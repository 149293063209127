export default [
  {
    title: 'home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    header: 'buyer',
  },
  {
    title: 'hotSales',
    id: 'open-offers-buyer',
    icon: 'DownloadIcon',
    route: {
      name: 'trading-open-offers-list',
      params: { param: 'open' },
    },
  },
  {
    id: 'requests-buyer',
    title: 'requests',
    icon: 'UploadIcon',
    route: {
      name: 'trading-requests-list',
      params: { param: 'own' },
    },
    children: [
      {
        title: 'createNew',
        route: {
          name: 'trading-requests-add',
          params: { id: 'new' },
        },
      },
      {
        title: 'myRequests',
        route: {
          name: 'trading-requests-list',
          params: { param: 'own' },
        },
      },
    ],
  },
  {
    title: 'myDealsBuyer',
    route: {
      name: 'trading-deals-list',
      params: { param: 'own-buyer' },
    },
    icon: 'ShoppingBagIcon',
  },
  {
    header: 'supplier',
  },
  {
    id: 'open-offers-supplier',
    title: 'hotSales',
    icon: 'DownloadIcon',
    route: 'trading-offers-list',
    children: [
      {
        title: 'createNew',
        route: {
          name: 'trading-offers-add',
          params: { id: 'new' },
        },
      },
      {
        title: 'myHotSales',
        route: 'trading-offers-list',
      },
    ],
  },
  {
    id: 'requests-supplier',
    title: 'requests',
    icon: 'UploadIcon',
    children: [
      {
        title: 'requestsList',
        route: {
          name: 'trading-requests-list',
          params: { param: 'all' },
        },
      },
      {
        title: 'myOffersOnRequests',
        disabled: true,
        route: '',
      },
    ],
  },
  {
    title: 'myDealsSupplier',
    route: {
      name: 'trading-deals-list',
      params: { param: 'own-supplier' },
    },
    icon: 'ShoppingBagIcon',
  },
  {
    title: 'documents',
    route: '',
    disabled: true,
    icon: 'FileTextIcon',
  },
  {
    title: 'chat',
    route: '',
    disabled: true,
    icon: 'MessageCircleIcon',
  },
  {
    title: 'notifications',
    route: 'apps-notifications',
    icon: 'BellIcon',
  },
  {
    title: 'myAccount',
    route: 'account-settings',
    icon: 'SettingsIcon',
  },
  {
    title: 'helpCenter',
    route: '',
    icon: 'HelpCircleIcon',
    disabled: true,
  },
];
