<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav
      v-if="isUnloginnedUser"
      class="nav align-items-center ml-auto"
    >
      <div
        v-if="isAdmin || isBuyer"
        class="stock-button-div"
      >
        <b-button
          variant="outline-primary"
          size="sm"
          class="stock-button"
          @click="isShowStockModal = true"
        >
          <span class="d-none d-sm-inline">
            {{ $t('stock') }}
          </span>
        </b-button>
      </div>
      <locale />
      <notification-dropdown />
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ personName }}
            </p>
            <span class="user-status">
              {{ personEmail }}
            </span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="getUserAvatar"
            :text="getAvatarText"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'account-settings' }"
        >
          <feather-icon
            size="16"
            icon="SettingsIcon"
            class="mr-50"
          />
          <span>{{ $t('myAccount') }}</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>{{ $t('logout') }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>

    <b-modal
      v-model="isShowStockModal"
      :title="$t('stock')"
      size="xl"
    >
      <stock mode="request" />
    </b-modal>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BButton,
} from 'bootstrap-vue';
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue';
import { avatarText } from '@core/utils/filter';
import router from '@/router';
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue';
import NotificationDropdown from '@core/layouts/components/app-navbar/components/NotificationDropdown.vue';
import TradingOffersNew from '@/views/trading/trading-offers/TradingOffersNew.vue';
import confirmModal from '@/views/apps/components/modals/confirmModal.vue';
import Stock from '@/views/trading/trading-stock/Stock.vue';

export default {
  components: {
    confirmModal,
    TradingOffersNew,
    BButton,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    NotificationDropdown,
    Locale,
    Stock,
    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isShowStockModal: false,
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters['profile/isAdmin'];
    },
    isBuyer() {
      return this.$store.getters['profile/isBuyerOnly'];
    },
    isUnloginnedUser() {
      return !this.$store.state.profile.isProfileDisabled;
    },
    userData() {
      return this.$store.state.profile.profile;
    },
    personName() {
      return this.userData?.profile?.person_name || '';
    },
    personEmail() {
      return this.userData?.email || '';
    },
    getUserAvatar() {
      return this.userData?.profile?.avatar_url || '';
    },
    getAvatarText() {
      return avatarText(this.personName);
    },
  },
  methods: {
    async logout() {
      await this.$store.dispatch('profile/logout');

      await router.push({ name: 'auth-login' });
    },
  },
};
</script>

<style lang="scss" scoped>
.stock-button-div {
  margin-right: 18px;
  .stock-button {
    //max-height: 20px;
  }
}

::v-deep .modal-title {
  font-size: 24px;
}
</style>
