<template>
  <b-card no-body>
    <b-card-header>
      <h5>
        {{ title }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-form-group class="request-type px-1">
        <b-row
          class="py-1 mb-1 request-type-option"
          :class="{ _active: selected === 'express' }"
        >
          <b-col
            cols="8"
          >
            <b-form-radio
              v-model="selected"
              value="express"
            >
              {{ $t('express') }}
            </b-form-radio>
          </b-col>
          <b-col
            cols="4"
          >
            <span class="text-muted">
              {{ $t('fewDays', { qty: 3 }) }}
            </span>
          </b-col>
        </b-row>
        <b-row
          class="py-1 mb-1 request-type-option"
          :class="{ _active: selected === 'bargaining' }"
        >
          <b-col
            cols="8"
          >
            <b-form-radio
              v-model="selected"
              value="bargaining"
            >
              {{ $t('bidding') }}
            </b-form-radio>
          </b-col>
          <b-col
            cols="4"
          >
            <span class="text-muted">
              {{ $t('someQtyDays', { qty: 10 }) }}
            </span>
          </b-col>
        </b-row>
        <b-row
          class="py-1 request-type-option"
          :class="{ _active: selected === 'tender' }"
        >
          <b-col
            cols="12"
          >
            <b-form-radio
              v-model="selected"
              value="tender"
            >
              {{ $t('tender') }}
            </b-form-radio>
          </b-col>
          <b-col cols="12">
            <b-collapse v-model="tenderOptionsStatus">
              <b-row class="p-2 tender-options">
                <b-col
                  class="p-0 d-flex justify-content-end"
                  cols="6"
                >
                  <b-form-radio-group
                    v-model="tenderValue"
                    class="d-flex flex-column"
                    :options="tenderOptions.column1"
                  />
                </b-col>
                <b-col
                  class="p-0 d-flex justify-content-start"
                  cols="6"
                >
                  <b-form-radio-group
                    v-model="tenderValue"
                    class="d-flex flex-column"
                    :options="tenderOptions.column2"
                  />
                </b-col>
              </b-row>
            </b-collapse>
          </b-col>
        </b-row>
      </b-form-group>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BFormRadio, BRow, BCol, BFormGroup, BCollapse, BFormRadioGroup,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BFormRadio,
    BRow,
    BCol,
    BFormGroup,
    BCollapse,
    BFormRadioGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    title: {},
    selectedType: {
      default: 'express',
    },
    selectedTypeTerm: {
      default: null,
    },
  },
  data: () => ({
    tenderOptionsStatus: false,
  }),
  computed: {
    tenderValue: {
      get() {
        return this.selectedTypeTerm;
      },
      set(value) {
        this.$emit('update:selectedTypeTerm', value);
      },
    },
    tenderOptions() {
      return {
        column1: [
          { text: this.$t('someQtyDays', { qty: 15 }), value: 15 },
          { text: this.$t('someQtyDays', { qty: 45 }), value: 45 },
        ],
        column2: [
          { text: this.$t('someQtyDays', { qty: 30 }), value: 30 },
          { text: this.$t('someQtyDays', { qty: 60 }), value: 60 },
        ],
      };
    },
    selected: {
      get() {
        return this.selectedType;
      },
      set(value) {
        this.$emit('update:selectedType', value);
        this.tenderValue = this.resolveTypeTerm(value);
        this.tenderOptionsStatus = value === 'tender';
      },
    },
  },
  methods: {
    resolveTypeTerm(type) {
      switch (type) {
        case 'express':
          return 3;
        case 'bargaining':
          return 10;
        case 'tender':
          return this.tenderValue;
        default:
          return 15;
      }
    },
  },
};
</script>

<style lang="scss">
@import 'src/@core/scss/base/core/colors/palette-variables.scss';
@import 'src/@core/scss/base/components/variables-dark';
.request-type-option {
  border: 1px solid $hr-border-color;
  border-radius: 6px;
  transition: all .4s ease;
  &._active {
    border: 1px solid $primary;
  }
}
.dark-layout {
  .request-type-option {
    border: 1px solid $theme-dark-border-color;
  }
}
</style>
