<template>
  <b-card no-body>
    <b-card-header>
      <h5>
        {{ title }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-form-group
        :label="headerLabel"
      >
        <v-select
          :value="user"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :clearable="false"
          transition=""
          label="company_name"
          :options="options"
          :reduce="option => option.id"
          @input="$emit('update:user', $event)"
        />
      </b-form-group>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BFormGroup,
} from 'bootstrap-vue';
import vSelect from 'vue-select';

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BFormGroup,
    vSelect,
  },
  props: {
    title: {},
    headerLabel: {},
    options: {
      type: Array,
      default: () => [],
    },
    user: {
      type: [Number, String],
      default: '',
    },
  },
};
</script>

<style lang="scss">
@import '../../@core/scss/base/core/colors/palette-variables';
@import '../../@core/scss/vue/libs/vue-flatpicker';
</style>
