import { offerStatuses } from '@/constants/offers.js';
import i18n from '@/libs/i18n';

export function getOfferInfo(offer) {
  const isProductsExist = !!offer.products.length;
  const [product] = offer.products;
  const name = isProductsExist ? product.name : '';
  const count = isProductsExist ? product.count : 0;
  const index = isProductsExist ? product.index : '';
  const productImage = product?.images[0]?.url || require('@/assets/images/placeholders/product-placeholder.svg');
  const currentStatus = offerStatuses[offer.status];

  const requestUser = offer.request?.user;

  return {
    isProductsExist,
    productName: name,
    productCount: `- ${count}`,
    productIndex: index,
    productImage,
    buyer: {
      exists: !!requestUser,
      companyName: requestUser?.company?.company_name || '',
      avatar_url: requestUser?.profile?.avatar_url || '',
      country: requestUser?.profile?.country?.name || '',
    },
    supplier: {
      companyName: offer.owned_by?.company_name || '',
      avatar_url: offer.owned_by?.profile?.avatar_url || '',
      country: offer.owned_by?.profile?.country?.name || '',
    },
    status: {
      name: currentStatus?.name,
      variant: currentStatus?.variant,
    },
    price: offer.price,
  };
}

export function resolveOfferStatusVariantAndIcon(status) {
  if (status > 1) {
    return { variant: 'success', icon: 'CheckCircleIcon', status: i18n.t('verified') };
  }

  return { variant: 'danger', icon: 'InfoIcon', status: i18n.t('notVerified') };
}

export const resolveProductsData = item => {
  let title = '';
  let qty = 0;
  let price = 0;
  let totalSum = 0;
  let productsInfo = i18n.t('thereIsNoItems');
  let daysLeft = 0;
  let productGroup = '';
  let productCode = '';
  let image = '';
  if (item.products.length) {
    const [product] = item.products;
    title = product.name;
    qty = product.count;
    price = product.price;
    totalSum = product.total_sum;
    image = product.images[0]?.url;
    productGroup = product.category || '';
    productCode = product.index || '';
    productsInfo = i18n.t('moreItemsInOffer', { qty: item.products.length - 1 });
  }
  if ((item.products.length - 1) === 1) {
    productsInfo = i18n.t('oneMoreItemInOffer');
  }
  if (item.valid_until) {
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate = new Date(item.valid_until);

    const dateDiff = Math.round((firstDate - new Date()) / oneDay);
    // eslint-disable-next-line no-nested-ternary
    daysLeft = dateDiff > 0
      ? dateDiff === 1 ? i18n.t('oneDayMore') : i18n.t('daysMore', { qty: dateDiff })
      : i18n.t('expired');
  }
  return {
    title,
    qty,
    price,
    totalSum,
    // eslint-disable-next-line global-require
    img: image || require('@/assets/images/placeholders/product-placeholder.svg'),
    productsInfo,
    daysLeft,
    productGroup,
    productCode,
  };
};
