import { render, staticRenderFns } from "./TradingRequestsNewProduct.vue?vue&type=template&id=03e670b5&scoped=true&"
import script from "./TradingRequestsNewProduct.vue?vue&type=script&lang=js&"
export * from "./TradingRequestsNewProduct.vue?vue&type=script&lang=js&"
import style0 from "./TradingRequestsNewProduct.vue?vue&type=style&index=0&id=03e670b5&prod&lang=scss&scoped=true&"
import style1 from "./TradingRequestsNewProduct.vue?vue&type=style&index=1&id=03e670b5&prod&lang=scss&"
import style2 from "./TradingRequestsNewProduct.vue?vue&type=style&index=2&id=03e670b5&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03e670b5",
  null
  
)

export default component.exports