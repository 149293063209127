<template>
  <div>
    <b-card>
      <b-row>
        <b-col />
        <b-col />
      </b-row>

      <div class="d-block text-center mt-3">
        <div class="main-div">
          <div class="d-flex justify-content-end mb-2">
            <vue-json-to-csv
              :json-data="tableRows"
              :labels="labelsForDownloadCsv"
              :csv-title="'Warehouse_csv_' + new Date().toISOString()"
              @success="() => handleCreatedCsvSuccess()"
              @error="val => handleDownloadCsvError(val)"
            >
              <b-button
                variant="primary"
                class="mr-2"
                :disabled="!tableRows.length"
              >
                {{ $t('downloadCsv') }}
              </b-button>
            </vue-json-to-csv>
          </div>

          <b-row>
            <b-col
              cols="12"
              md="3"
              class="mb-md-0 mb-2"
            >
              <label>{{ $t('productGroup') }}</label>
              <div>
                <treeselect
                  v-model="productGroupsSearch"
                  :multiple="true"
                  :options="productGroupsTreeData"
                  :no-children-text="'No categories'"
                  :placeholder="$t('productGroup')"
                  :max-height="100"
                />
              </div>
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="mb-md-0 mb-2"
            >
              <label>{{ $t('tnVed') }}</label>
              <div>
                <treeselect
                  v-model="tnVedValues"
                  :multiple="true"
                  :options="tnVedTreeData"
                  :no-children-text="'No categories'"
                  :placeholder="$t('productGroup')"
                  :limit="1"
                />
              </div>
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="mb-md-0 mb-2"
            >
              <label>{{ $t('manufacturer') }}</label>
              <b-form-input
                id="manufacturer"
                v-model="manufacturerSearch"
                name="manufacturer"
                :placeholder="$t('manufacturer')"
              />
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="mb-md-0 mb-2"
            >
              <label>{{ $t('codeEAN') }}</label>
              <b-form-input
                id="codeEAN"
                v-model="eanCodeSearch"
                name="codeEAN"
                :placeholder="$t('codeEAN')"
              />
            </b-col>
          </b-row>

          <!-- table -->
          <div class="table-container">
            <vue-good-table
              ref="vueGoodTableWarehouse"
              :columns="tableColumns"
              :rows="tableRows"
              :row-style-class="''"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm }"
              :select-options="{
                enabled: true,
                selectOnCheckboxOnly: true,
                selectAllByPage: false,
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true,
                selectAllByGroup: true,
              }"
              :sort-options="{
                enabled: true,
              }"
              :pagination-options="{
                enabled: true,
                perPage: tableParams.currentPerPage,
                mode: 'records',
              }"
              style-class="vgt-table condensed"
              @on-page-change="handlePageChange"
              @on-per-page-change="handlePageChange"
              @on-selected-rows-change="handleSelectionChanged"
            >
              <div slot="emptystate">
                <span>
                  {{
                    productGroupsSearch.length || manufacturerSearch.length
                      || eanCodeSearch.length || tnVedValues.length ?
                        $t('noProductsBySearch') : $t('noProducts')
                  }}
                </span>
              </div>
              <template
                slot="table-column"
                slot-scope="props"
              >
                <div>
                  <span>
                    {{ props.column.label }}
                  </span>
                </div>
              </template>

              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.field === 'group_id'">
                  {{ $store.state.products.groups.find(group => group.id === props.row.group_id) ? $store.state.products.groups.find(group => group.id === props.row.group_id).name : 'Default' }}
                </span>
                <span v-else-if="props.column.field === 'supplier_id'">
                  {{ props.formattedRow[props.column.field] }}
                </span>
                <span
                  v-else-if="props.column.field === 'favorite'"
                  class="mt-1 favorite-icon"
                >
                  <b-icon-star-fill
                    v-if="props.row.favorite"
                    font-scale="0.85"
                    @click="favoriteClick(props.row)"
                  />
                  <b-icon-star
                    v-else
                    font-scale="0.85"
                    @click="favoriteClick(props.row)"
                  />
                </span>
                <span
                  v-else-if="props.column.field === 'actions'"
                  class=""
                >
                  <b-dropdown
                    variant="outline-primary"
                    size="sm"
                  >
                    <template #button-content>
                      <b-icon-plus-square
                        font-scale="0.95"
                        class="plus-icon"
                      />
                      <span class="mr-25 align-middle">
                        {{ $t('addToRequest') }}
                      </span>
                    </template>

                    <b-dropdown-item
                      href="#"
                      @click="requestOfItemClick(null, props.row)"
                    >
                      <div>
                        <span style="font-weight: bold">
                          + {{ $t('createRequest') }}
                        </span>
                      </div>
                    </b-dropdown-item>

                    <b-dropdown-item
                      v-for="request in requests"
                      :key="request.id"
                      href="#"
                      @click="requestOfItemClick(request.id, props.row)"
                    >
                      <div class="request-content">
                        <div>
                          <span>#{{ request.id }} </span>
                          <span>{{ request.title }}: </span>
                        </div>
                        <div
                          v-for="(product, index) in request.products"
                          :key="product.id"
                        >
                          <div class="product-name">
                            <span>{{ product.name }}</span>
                          </div>
                          <div class="product-count">
                            <span> ({{ product.count }})</span>
                            <span v-if="index !== request.products.length - 1">
                              ,
                            </span>
                          </div>
                        </div>
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <template
                slot="pagination-bottom"
              >
                <div />
              </template>
            </vue-good-table>
          </div>

          <div class="d-flex flex-wrap justify-content-between">
            <div class="d-flex justify-content-start flex-wrap text-style">
              <div class="d-flex align-items-center">
                <div class="request-select-title">
                  <span>{{ $t('addSelectedProductsToRequest') }}:</span>
                </div>
                <div class="request-select-div">
                  <b-form-select
                    v-model="requestForProductsId"
                    class="ml-1 request-select"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="false"
                        disabled
                      >
                        <span>-- {{ $t('selectRequest') }} --</span>
                      </b-form-select-option>
                    </template>

                    <b-form-select-option
                      :value="null"
                    >
                      <span>
                        <span>+ {{ $t('createRequest') }}</span>
                      </span>
                    </b-form-select-option>

                    <b-form-select-option
                      v-for="request in requests"
                      :key="request.id"
                      :value="request.id"
                    >
                      <div class="request-content">
                        <div>
                          <span>#{{ request.id }} </span>
                          <span>{{ request.title }}: </span>
                        </div>
                        <div
                          v-for="(product, index) in request.products"
                          :key="product.id"
                        >
                          <div class="product-name">
                            <span>{{ product.name }}</span>
                          </div>
                          <div class="product-count">
                            <span> ({{ product.count }})</span>
                            <span v-if="index !== request.products.length - 1">
                              ,
                            </span>
                          </div>
                        </div>
                      </div>
                    </b-form-select-option>
                  </b-form-select>
                </div>
                <div class="align-items-center">
                  <b-button
                    variant="primary"
                    :disabled="(requestForProductsId !== null && !requestForProductsId) || !selectedRows.length"
                    @click="addItemsToRequestClick()"
                  >
                    {{ $t('add') }}
                  </b-button>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end flex-wrap text-style">
              <div class="d-flex align-items-center">
                <span class="d-flex">
                  {{ $t('itemsPerPage') }}:
                </span>
                <b-form-select
                  v-model="tableParams.currentPerPage"
                  :options="['10','25','50','100']"
                  class="mx-1 d-flex form-select"
                  @input="value => $refs.vueGoodTableWarehouse.perPageChanged({currentPerPage: value})"
                />
              </div>

              <div class="d-flex align-items-center ml-2">
                <span class="text-nowrap">
                  {{ tableParams.currentPerPage * (tableParams.currentPage - 1) + 1 }}
                  - {{ tableParams.currentPerPage * tableParams.currentPage >= tableParams.totalRows ? tableParams.totalRows : tableParams.currentPerPage * tableParams.currentPage }}
                  {{ $t('of') }} {{ tableParams.totalRows }} {{ $t('rows') }}
                </span>
              </div>

              <div class="d-flex align-items-center ml-3">
                <b-pagination
                  :value="1"
                  :total-rows="tableParams.totalRows"
                  :per-page="tableParams.currentPerPage"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1"
                  @input="value => $refs.vueGoodTableWarehouse.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div />
    </b-card>

    <b-modal
      v-model="isShowTradingRequestsEdit"
      size="xl"
      @hide="fetchRequests"
    >
      <trading-requests-new
        :is-new-mode-prop="!modalRequestId"
        :request-id-prop="modalRequestId"
        :items-for-request-prop="itemsForRequestProp"
        @close-requests-new="handleCloseRequestsNew"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BRow,
  BPagination,
  BFormSelect,
  BFormSelectOption,
  BCard,
  BDropdownItem,
  BDropdown,
  BIconStarFill,
  BIconStar, BFormInput, BIconPlusSquare,
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import Ripple from 'vue-ripple-directive';
import i18n from '@/libs/i18n';
import readXlsxFile from 'read-excel-file';
import 'vue-good-table/dist/vue-good-table.css';
// import the component
// eslint-disable-next-line import/no-extraneous-dependencies
import Treeselect from '@riophae/vue-treeselect';
// import the styles
// eslint-disable-next-line import/no-extraneous-dependencies
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
// import "vue-select/dist/vue-select.css";
import tnVedTreeData from '@/views/data/warehouseMockData';
import store from '@/store';
import TradingRequestsNew from '@/views/trading/trading-requests/TradingRequestsNew.vue';
import VueJsonToCsv from 'vue-json-to-csv';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BFormInput,
    BRow,
    BCol,
    BButton,
    BPagination,
    BFormSelect,
    BFormSelectOption,
    VueGoodTable,
    BCard,
    BIconStarFill,
    BIconStar,
    BIconPlusSquare,
    Treeselect,
    BDropdownItem,
    BDropdown,
    TradingRequestsNew,
    VueJsonToCsv,
  },
  directives: {
    Ripple,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    products: {
      type: Array,
      default: (() => []),
    },
    addBtnDisabled: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'request',
    },
    excelRowsFullReceived: {
      type: Array,
      default: (() => []),
    },
  },
  data: () => ({
    modalVisible: false,
    selectedCategory: null,
    isImportProductsLoading: false,
    selected: [],
    selectObjects: {},
    selectedRows: [],
    // excelRowsFull: [],
    tableParams: {
      currentPage: 1,
      currentPerPage: 10,
      totalRows: 0,
      totalPages: 1,
    },
    isTableContainsHeaders: true,
    searchTerm: '',
    eanCodeSearch: '',
    productGroupsSearch: [],
    productGroupsTreeData: [
      {
        id: 1,
        label: 'DEFAULT',
        children: [],
      },
    ],
    tnVedValues: [],
    manufacturerSearch: '',
    requestForProductsId: false,
    tableRowsFull: [],
    excelRowsFull: [],
    isShowTradingRequestsEdit: false,
    modalRequestId: null,
    itemsForRequestProp: [],
  }),
  computed: {
    requests() {
      return store.state.requests.requests.filter(request => request.can_edit);
    },
    tableRows() {
      let rows = [...this.tableRowsFull];

      if (this.productGroupsSearch.length) {
        rows = rows.filter(row => this.productGroupsSearch.some(productGroupId => row.group_id === productGroupId));
      }

      if (this.tnVedValues.length) {
        rows = rows.filter(row => this.tnVedValues.some(tnVedValue => String(row.tnv).startsWith(tnVedValue)));
      }

      if (this.manufacturerSearch.length) {
        rows = rows.filter(row => row.name.toLocaleLowerCase().includes(this.manufacturerSearch.toLocaleLowerCase())); // by product name
      }

      if (this.eanCodeSearch.length) {
        rows = rows.filter(row => String(row.index).includes(this.eanCodeSearch)); // by part number
      }

      return rows;
    },
    labelsForDownloadCsv() {
      const resObj = {};

      this.tableColumns.filter(column => !['actions', 'favorite'].includes(column.field)).forEach(column => {
        resObj[column.field] = { title: column.label };
      });

      return resObj;
    },
    tnVedTreeData() {
      return tnVedTreeData;
    },
    isAdmin() {
      return this.$store.getters['profile/isAdmin'];
    },
    tableColumns() {
      const columns = this.mode === 'request' ? [
        // {
        //   label: '',
        //   field: 'favorite',
        //   width: '30px',
        //   sortable: false,
        // },
        {
          field: 'group_id',
          label: i18n.t('productGroup'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'name',
          label: i18n.t('productName'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'index',
          label: i18n.t('partNumber'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'tnv',
          label: i18n.t('tnVed'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'delivery_type',
          label: i18n.t('deliveryType'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'note',
          label: i18n.t('comments'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'actions',
          label: i18n.t('actions'),
          width: '180px',
          sortable: false,
        },
      ] : [
        {
          label: '',
          field: 'favorite',
          width: '30px',
          sortable: false,
        },
        {
          field: 'group_id',
          label: i18n.t('productGroup'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'name',
          label: i18n.t('productName'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'index',
          label: i18n.t('partNumber'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'tnv',
          label: i18n.t('tnVed'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'count',
          label: i18n.t('quantity'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'price',
          label: i18n.t('latestPrice'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'delivery_type',
          label: i18n.t('deliveryType'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'note',
          label: i18n.t('comments'),
          width: '170px',
          sortable: true,
        },
        {
          field: 'actions',
          label: i18n.t('actions'),
          width: '180px',
          sortable: false,
        },
      ];

      if (this.isAdmin) {
        columns.splice(4, 0, {
          field: 'supplier_id',
          label: i18n.t('supplier'),
          width: '170px',
          sortable: true,
        });
      }

      return columns;
    },
  },
  watch: {
    tableRows(rows) { // temporary - without backend
      this.tableParams.totalRows = rows.length;
    },
  },
  async mounted() {
    if (this.$route.params.id && this.$route.params.id === 'new') {
      this.showProductEditing = true;
    }

    this.draggableProducts = this.products;

    this.tableParams.totalRows = this.tableRows.length;
    this.tableParams.totalPages = Math.round(this.tableParams.totalRows / this.tableParams.currentPerPage);

    this.$nextTick(() => {
      this.initSelectedRows();
    });

    await this.$store.dispatch('products/fetch'); // fetch groups

    await this.fetchItems();

    await this.fetchRequests();

    // await store.dispatch('users/fetch', {
    //   per_page: 100,
    //   page: 1,
    // });
    //
    // console.log('usersList');
    // console.log(this.$store.state.users.users);
  },
  methods: {
    requestOfItemClick(requestId, row) {
      this.modalRequestId = requestId;

      const index = this.tableRowsFull.findIndex(tableRow => tableRow.index === row.index);

      this.itemsForRequestProp = [this.tableRowsFull[index]];

      this.$nextTick(() => {
        this.isShowTradingRequestsEdit = true;
      });
    },
    addItemsToRequestClick() {
      this.modalRequestId = this.requestForProductsId;

      this.itemsForRequestProp = JSON.parse(JSON.stringify(this.selectedRows));

      this.$nextTick(() => {
        this.isShowTradingRequestsEdit = true;
      });
    },
    initSelectedRows() {
      this.$nextTick(() => {
        this.selectedRows = this.$refs.vueGoodTableWarehouse.selectedRows;
      });
    },
    async fetchItems() {
      await this.$http.get('/v1/stock?page=1&per_page=100').then(({ data }) => {
        this.tableRowsFull = data.data.map((item, index) => ({
          id: item.id,
          group_id: 1,
          name: item.name,
          index: item.index,
          tnv: item.tnv_codes[0].code,
          note: item.description,
          count: 1,
          price: item.last_price,
          delivery_type: 'retail',
          supplier_id: index === 1 ? 'Anthony Smith' : 'James Bond',
        }));
      });
    },
    async fetchRequests() {
      try {
        await store.dispatch('requests/fetch', {
          fetchParam: this.isAdmin ? 'all' : 'own',
          data: {
            per_page: 100,
            page: 1,
          },
        });
      } catch (err) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('fetchingRequestsError'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
    async handleCsvUploaded() {
      // here - uploadDocuments()

      const xlsxFile = this.$refs.refInputEl.files ? this.$refs.refInputEl.files[0] : null;

      this.excelRowsFull = await readXlsxFile(xlsxFile);

      this.modalVisible = true;
    },
    // async handleImportProductsClick() {
    //   const newProducts = [];
    //   const keys = Object.keys(this.selectObjects);
    //
    //   this.selectedRowIndexes.forEach(rowIndex => {
    //     const product = JSON.parse(JSON.stringify(this.productDefaultProperties));
    //
    //     keys.forEach(key => {
    //       if (this.selectObjects[key]) {
    //         if (this.selectObjects[key] === 'group_id') {
    //           const productGroup = this.$store.state.products.groups.find(group => group.name === this.tableRows[rowIndex][key]);
    //
    //           if (productGroup) {
    //             product[this.selectObjects[key]] = productGroup.id;
    //           } else {
    //             const defaultGroup = this.$store.state.products.groups.find(group => group.name === 'DEFAULT');
    //
    //             if (defaultGroup) {
    //               product[this.selectObjects[key]] = defaultGroup.id;
    //             } else {
    //               product[this.selectObjects[key]] = this.$store.state.products.groups[0].id;
    //             }
    //           }
    //         } else {
    //           product[this.selectObjects[key]] = this.tableRows[rowIndex][key];
    //         }
    //       }
    //     });
    //
    //     newProducts.push(product);
    //   });
    //
    //   this.isImportProductsLoading = true;
    //   let showOkMessage = true;
    //
    //   newProducts.forEach(async (newProduct, index) => {
    //     try {
    //       const { data } = await this.$http.post('/products', newProduct);
    //
    //       if (data.status) {
    //         this.$emit('update-products', data.data.product);
    //       }
    //
    //       if (index === newProducts.length - 1 && showOkMessage) {
    //         this.$toast({
    //           component: ToastificationContent,
    //           props: {
    //             title: i18n.t('productsImportedSuccessfully'),
    //             icon: 'CheckCircleIcon',
    //             variant: 'success',
    //           },
    //         });
    //       }
    //     } catch (err) {
    //       showOkMessage = false;
    //     }
    //   });
    //
    //   this.isImportProductsLoading = false;
    // },
    preparingRowsData(arrayOfArrays) {
      return arrayOfArrays.map(array => {
        const obj = {};

        array.forEach((elem, index) => {
          obj[index] = elem ? String(elem) : elem;
        });

        return obj;
      });
    },
    preparingColumnsData(columnHeaders, maxArrayLength = 0) {
      const array = Array.isArray(columnHeaders) ? columnHeaders : Object.values(columnHeaders);

      if (array.length < maxArrayLength) {
        array.push(...Array(maxArrayLength - array.length).fill('-'));
      }

      return array.map((elem, index) => ({
        label: elem || '-',
        field: `${index}`,
        width: '170px',
        thClass: '',
        sortable: true,
      }));
    },
    handlePageChange(params) {
      this.tableParams.currentPage = params.currentPage;
      this.tableParams.prevPage = params.prevPage;
      this.tableParams.currentPerPage = params.currentPerPage;
      this.tableParams.totalPages = params.total;
      this.tableParams.totalRows = this.tableRows.length;
    },
    handleSelectionChanged({ selectedRows }) {
      this.selectedRows = selectedRows;
    },
    close() {
      this.$emit('close-modal');
    },
    async favoriteClick(row) {
      // const initValue = this.tableRows[row.originalIndex].favorite;
      // const index = this.tableRowsFull.findIndex(tableRow => tableRow.index === row.index);
      //
      // this.tableRowsFull[index].favorite = !this.tableRowsFull[index].favorite;
      //
      // const { data } = await this.$http.post(`/v1/items/favorites/${row.id}`);
      //
      // if (!data.status) {
      //   this.tableRows[row.originalIndex].favorite = initValue;
      // }
    },
    updateProductsAll(updatedProducts) {
      this.tableRowsFull = [...this.tableRowsFull, ...updatedProducts.map(product => ({ ...product, group_id: 1, supplier_id: 'Anthony Smith' }))];

      this.$refs.vueGoodTableWarehouse.pageChanged({ currentPage: this.tableParams.currentPage });
    },
    handleCreatedCsvSuccess() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('csvCreatedSuccessfully'),
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      });
    },
    handleDownloadCsvError(err) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('somethingWentWrong', { msg: err }),
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      });
    },
    handleCloseRequestsNew() {
      this.isShowTradingRequestsEdit = false;
    },
  },
};
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import 'vue-good-table/dist/vue-good-table';

.divider {
  margin: 0;
}

.card-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.modal-class {
  background-color: #1ab7ea !important;
}

.table-container {
  width: 100%;
  overflow-x: auto;
  padding-top: 50px !important;
}

.form-select {
  width: fit-content;
}

.text-style {
  color: #4e5154 !important;
}

.custom-class {
  background-color: #1ab7ea !important;
}

::v-deep .custom-th-class {
  background-color: #1ab7ea !important;
  text-align: center !important;
}

::v-deep .pointer {
  pointer-events: all !important;
  color: #1ab7ea !important;
}

::v-deep .custom-td-class {
  background-color: green !important;
}

::v-deep .vgt-checkbox-col {
  background-color: #1ab7ea !important;
}

::v-deep .column-select {
  position: absolute;
  top: -50px;
  width: 80%;
  z-index: 1000 !important;
}

.select-div {
  z-index: 1000 !important;
}

::v-deep .vgt-responsive {
  overflow-x: unset;
}

::v-deep .not-selected {
  color: #d0d0d0;
}

::v-deep .button-spinner {
  width: 14px;
  height: 14px;
}

::v-deep .favorite-icon {
  pointer-events: all;
  cursor: pointer;
  //
  //:hover {
  //  background-color: #1ab7ea;
  //}
}

.plus-icon {
  margin-right: 2px;
  padding-bottom: 2px;
}

.request-select-title {
  width: fit-content;
}

.request-select-div {
  width: 300px;
  margin-right: 40px;

  .request-select {
    width: 100%;
  }
}

.product-name {
  max-width: 200px;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  align-content: center;
  margin-left: 5px;
}

.product-count {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  align-content: center;
}

.request-content {
  max-width: 700px;
  text-overflow: ellipsis;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
}
</style>
