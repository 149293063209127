<template>
  <div>
    <b-modal
      ref="my-modal"
      :visible="modalVisible"
      :title="title"
      centered
      hide-footer
      @hide="handleHideModal"
    >
      <div class="d-block text-center mt-3">
        <h3>{{ modalMessage }}</h3>
      </div>
      <div>
        <b-row>
          <b-col>
            <b-button
              class="mt-3"
              variant="outline-primary"
              block
              @click="handleNoClick"
            >
              {{ cancelText }}
            </b-button>
          </b-col>
          <b-col>
            <b-button
              class="mt-3"
              variant="outline-primary"
              block
              @click="handleYesClick"
            >
              <b-spinner
                v-if="isOkButtonSpinning"
                class="align-middle mr-1 button-spinner"
              />
              {{ okText }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BSpinner,
} from 'bootstrap-vue';

export default {
  name: 'ConfirmModal',
  components: {
    BSpinner,
    BButton,
    BRow,
    BCol,
  },
  props: {
    modalVisible: {
      type: Boolean,
      default: false,
    },
    isOkButtonSpinning: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    modalMessage: {
      type: String,
      default: 'Are you sure?',
    },
    cancelText: {
      type: String,
      default: 'No, cancel',
    },
    okText: {
      type: String,
      default: 'Yes, sure',
    },
  },
  methods: {
    handleHideModal() {
      this.$emit('modal-hidden');
    },
    handleYesClick() {
      this.$emit('yes-clicked');
    },
    handleNoClick() {
      this.$emit('no-clicked');
    },
  },
};
</script>

<style scoped lang="scss">
.button-spinner {
  width: 14px;
  height: 14px;
}
</style>
