import i18n from '@/libs/i18n';

const productFieldsData = {
  group_id: i18n.t('productGroup'),
  name: i18n.t('productName'),
  index: i18n.t('partNumber'),
  count: i18n.t('quantity'),
  warranty: i18n.t('warranty'),
  tnv: i18n.t('tnVed'),
  delivery_type: i18n.t('deliveryType'),
  note: i18n.t('comments'),
  price: i18n.t('unitPrice'),
  vat: i18n.t('vat'),
};

export default productFieldsData;
