<template>
  <div>
    <div class="d-flex flex-column align-items-center w-100">
      <div
        class="d-flex flex-column align-items-center w-100"
      >
        <b-card
          no-body
          class="mt-1 mb-1 w-100 transaction-item flex-row justify-content-between d-flex"
        >
          <div
            v-if="!isBuyerEditing || isOffer"
            class="d-flex p-2"
          >
            <b-avatar
              size="40"
              variant="light-primary"
              class="mr-2"
              :src="buyerAvatar"
              :text="getAvatarText(buyer ? buyer.company : null)"
            />
            <div>
              <h6>{{ buyer ? buyer.company_name : '' }}</h6>
              <span class="company-card-country">
                {{ $t('buyer') }}
              </span>
            </div>
            <feather-icon
              v-if="!isOffer && deal.can_edit_buyer"
              size="18"
              icon="EditIcon"
              class="cursor-pointer transaction-item__edit"
              @click="isBuyerEditing = true"
            />
          </div>
          <div
            v-else
            class="p-2 w-100"
          >
            <b-form-group
              label="Available Companies"
              label-for="companies"
            >
              <v-select
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="buyerCompanies"
                label="company_name"
                :clearable="false"
                input-id="companies"
                @input="updateBuyer"
              />
            </b-form-group>
          </div>
        </b-card>
        <feather-icon
          icon="ChevronDownIcon"
          size="24"
        />
      </div>
      <draggable
        v-model="transactionsWithoutMainBuyer"
        class="w-100"
        :options="{disabled: focused}"
      >
        <div
          v-for="(transaction, index) in transactionsWithoutMainBuyer"
          :key="index"
          class="d-flex flex-column align-items-center w-100"
        >
          <b-card
            :class="{ 'mb-2': index !== transactionsWithoutMainBuyer.length - 1 }"
            no-body
            class="mt-1 w-100 transaction-item flex-row justify-content-between d-flex"
          >
            <b-row class="d-flex justify-content-between w-100">
              <b-col cols="4">
                <div
                  class="d-flex p-2"
                >
                  <b-avatar
                    size="40"
                    variant="light-primary"
                    class="mr-2"
                    :text="getAvatarText(transaction.buyer_legal)"
                  />
                  <div>
                    <h6>{{ transaction.buyer_legal.company_name }}</h6>
                    <span class="company-card-country">
                      {{ $t('intermediary') }}
                    </span>
                  </div>
                </div>
              </b-col>
              <b-col
                cols="12"
                lg="4"
              >
                <div
                  class="d-flex pt-50 px-2"
                >
                  <b-form-group
                    class="mt-0"
                    :label="$t('margin')"
                    label-for="margin"
                  >
                    <b-row class="d-flex align-items-center">
                      <b-col :cols="deal.status === 1 || !deal.status ? 8 : 10">
                        <b-form-input
                          v-if="deal.status === 1 || !deal.status"
                          v-model.number="transaction.margin"
                          type="number"
                          @focusin="marginFormInputFocused(index)"
                          @focusout="marginFormInputUnFocused(index)"
                        />
                        <div
                          v-else
                          class="w-100 mr-3"
                        >
                          <span>
                            {{ transaction.margin }}%
                          </span>
                        </div>
                      </b-col>
                      <b-col
                        v-if="deal.status === 1 || !deal.status"
                        cols="4"
                        class="p-0"
                      >
                        <span>%</span>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <div class="d-flex align-items-center">
                    <b-button
                      v-if="isMarginChanged(transaction)"
                      variant="primary"
                      :disabled="!isOfferEditable"
                      @click="saveMargin(transaction)"
                    >
                      {{ $t('save') }}
                    </b-button>
                    <template v-else>
                      <b-avatar
                        size="32"
                        variant="light-success"
                        class="mr-1"
                      >
                        <feather-icon icon="CheckCircleIcon" />
                      </b-avatar>
                      <span class="font-weight-bold">
                        {{ $t('saved') }}
                      </span>
                    </template>
                  </div>
                </div>
              </b-col>
            </b-row>
            <div class="d-flex flex-column justify-content-between p-50">
              <feather-icon
                v-if="isOfferEditable"
                size="18"
                icon="XIcon"
                class="cursor-pointer"
                @click="removeTransaction(transaction)"
              />
            </div>
          </b-card>
        </div>
      </draggable>
      <b-card
        v-if="isOfferEditable"
        no-body
        class="mt-1 mb-2 w-100 cursor-pointer transaction-item transaction-item-add-new "
        @click="isNewItemActive = true"
      >
        <div
          v-if="!isNewItemActive"
          class="d-flex align-items-center p-2"
        >
          <feather-icon
            icon="PlusIcon"
            size="36"
            class="text-muted"
          />
          <h3 class="text-muted mb-0 ml-2">
            {{ $t('addIntermediary') }}
          </h3>
        </div>
        <div
          v-else
          class="p-2"
        >
          <b-form-group
            label="Available Companies"
            label-for="companies"
          >
            <v-select
              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
              :options="companiesOptions"
              label="company_name"
              :clearable="false"
              input-id="companies"
              @input="addNew"
            />
          </b-form-group>
        </div>
      </b-card>
      <feather-icon
        icon="ChevronDownIcon"
        size="24"
      />
      <div class="d-flex flex-column align-items-center w-100">
        <b-card
          no-body
          class="mt-1 w-100 transaction-item"
        >
          <div class="d-flex p-2">
            <b-avatar
              size="40"
              variant="light-primary"
              class="mr-2"
              :src="supplierAvatar"
              :text="getAvatarText(supplier ? supplier.company : null)"
            />
            <div>
              <h6>{{ supplier ? supplier.company_name : '' }}</h6>
              <span class="company-card-country">
                {{ $t('supplier') }}
              </span>
            </div>
          </div>
        </b-card>
      </div>
    </div>
    <b-button
      v-if="showSave"
      class="mt-2"
      variant="primary"
      :disabled="isDocumentsGeneration || deal.status > 1 || !isOfferEditable"
      @click="saveChain"
    >
      {{ buttonText || $t('saveChainAndGenerateDocs') }}
    </b-button>
    <b-modal
      id="change-buyer"
      v-model="modalOpened"
      centered
      title="Warning"
    >
      <span>{{ $t('sureToSaveChanges') }}</span>
      <template #modal-footer>
        <b-button
          variant="secondary"
          @click="closePopup"
        >
          {{ $t('cancel') }}
        </b-button>
        <b-button
          variant="primary"
          class="btn-cart"
          @click="setNewBuyer"
        >
          <feather-icon
            icon="SaveIcon"
            class="mr-50"
          />
          <span>{{ $t('save') }}</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BButton, BAvatar, BFormGroup, BRow, BCol, BFormInput, BModal,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import { avatarText } from '@core/utils/filter';
import draggable from 'vuedraggable';
import ToastificationContent from '@core/components/toastification/ToastificationContent';

export default {
  components: {
    BModal,
    BCard,
    BButton,
    BAvatar,
    BFormGroup,
    vSelect,
    draggable,
    BRow,
    BCol,
    BFormInput,
  },
  props: {
    transactions: {
      type: Array,
      default: (() => []),
    },
    isOffer: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: '',
    },
    showSave: {
      type: Boolean,
      default: true,
    },
    isOfferEditable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      visible: true,
      isNewItemActive: false,
      savedTransactions: null,
      focused: false,
      isDocumentsGeneration: false,
      isBuyerEditing: false,
      buyerToUpdate: null,
      modalOpened: false,
    };
  },
  computed: {
    deal() {
      return this.$store.state.deal.deal || {};
    },
    buyerAvatar() {
      return this.buyer?.profile?.avatar_url;
    },
    supplierAvatar() {
      return this.supplier?.profile?.avatar_url;
    },
    companiesOptions() {
      return this.$store.state.offers.legalOptions;
    },
    buyerCompanies() {
      return this.$store.state.offers.legalBuyers;
    },
    transactionsWithoutMainBuyer: {
      get() {
        return this.transactions.slice(1);
      },
      set(value) {
        const allTransactions = [this.transactions[0], ...value];
        this.$store.dispatch('offers/updateLegalMembers', { id: this.offer.id, payload: allTransactions });
      },
    },
    offer() {
      return this.isOffer ? this.$store.state.offers.currentOffer : this.deal?.offer;
    },
    supplier() {
      const legals = this.offer?.legal_members;
      const [lastLegal] = legals.slice(-1);
      return lastLegal?.seller_legal || null;
    },
    buyer() {
      return this.isOffer ? this.offer?.request?.user
          || {
            ...this.$store.state?.offers?.currentOffer?.owned_by?.profile,
            company_name: this.$store.state?.offers?.currentOffer?.owned_by?.company_name,
            role: 'Supplier',
          }
          || null
        : this.deal?.offer?.legal_members[0].buyer_legal || null;
    },
  },
  watch: {
    transactions: {
      immediate: true,
      handler(value) {
        this.savedTransactions = JSON.parse(JSON.stringify(value));
      },
    },
  },
  async created() {
    if (this.deal.can_edit_buyer) {
      await this.$store.dispatch('offers/fetchLegalBuyers');
    }
  },
  methods: {
    saveMargin(transaction) {
      const newMargin = transaction.margin || 0;
      this.$store.dispatch(
        'offers/updateOfferLegalMargin',
        { id: transaction.id, margin: newMargin, offerId: this.offer.id },
      );
      const updatableTransaction = this.savedTransactions.find(item => item.id === transaction.id);
      updatableTransaction.margin = newMargin;

      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('marginSavedSuccessfully'),
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      });
    },
    isMarginChanged(transaction) {
      const initTransaction = this.savedTransactions.find(item => item.id === transaction.id);
      const { margin } = transaction;
      return +initTransaction.margin !== +margin || +margin === 0;
    },
    closePopup() {
      this.modalOpened = false;
      this.isBuyerEditing = false;
    },
    async setNewBuyer() {
      try {
        await this.$store.dispatch('deal/updateBuyer', { dealId: this.deal.id, id: this.buyerToUpdate.id });
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('somethingWentWrong'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      } finally {
        this.closePopup();
      }
    },
    updateBuyer(value) {
      this.buyerToUpdate = value;
      this.modalOpened = true;
    },
    addNew(value) {
      this.$emit('add-new', value);
      this.isNewItemActive = false;
    },
    async saveChain() {
      this.isDocumentsGeneration = true;

      try {
        const offerId = this.offer.id;

        if (this.isOffer) {
          await this.$store.dispatch('offers/generateDocuments', offerId);
        } else {
          await this.$store.dispatch('deal/generateDocuments', offerId);
        }
        await this.$store.dispatch('offers/fetchLegalMembers', offerId);
        this.$emit('save-chain');
      } finally {
        this.isDocumentsGeneration = false;
      }
    },
    removeTransaction(item) {
      this.$emit('remove-item', item.id);
    },
    getAvatarText(data) {
      return avatarText(data?.company_name || '');
    },
    marginFormInputFocused(index) {
      this.focused = true;

      if (!this.transactionsWithoutMainBuyer[index].margin) this.transactionsWithoutMainBuyer[index].margin = undefined;
    },
    marginFormInputUnFocused(index) {
      this.focused = false;

      if (!this.transactionsWithoutMainBuyer[index].margin) this.transactionsWithoutMainBuyer[index].margin = 0;
    },
  },
};
</script>

<style lang="scss">
@import 'src/@core/scss/base/core/colors/palette-variables.scss';
@import "src/@core/scss/base/components/_variables-dark.scss";
.transaction-item {
  border: 1px solid $table-border-color;
  border-radius: 6px;
  transition: all .4s ease;
  &-add-new {
    border: 1px dashed $table-border-color;
  }
}
.transaction-item__edit {
  position: absolute;
  top: calc(50% - 10px);
  right: 20px;
}
.dark-layout {
  .transaction-item {
    border:  1px solid $theme-dark-widget-bg;
    &-add-new {
      border: 1px dashed $theme-dark-widget-bg;
    }
  }
}
</style>
