<template>
  <b-nav-item-dropdown
    ref="dropDownRef"
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="countNotification"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
        @click="getNotifications"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t('notifications') }}
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ countNotification }} {{ $t('new') }}
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      v-if="notifications.length"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link
        v-for="notification of notifications"
        :key="notification.id"
        @click="goTo(notification)"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :src="notification.avatar_url ? notification.avatar_url : null"
              :text="notification.avatar_url ? notification.avatar_url : null"
              variant="light-success"
            />
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">
            {{ notification.description }}
          </small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        :to="{name: 'apps-notifications'}"
        @click="hideDropDown"
      >
        {{ $t('readAllNotifications') }}
      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton,
} from 'bootstrap-vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import Ripple from 'vue-ripple-directive';
import axios from '@/libs/axios';
import { goToNotificationRoute } from '@/utils/notificationsUtils.js';

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      countNotification: 0,
      notifications: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      dropDownRef: null,
    };
  },
  async mounted() {
    await this.getNotifications();

    setInterval(() => {
      this.getNotifications();
    }, 30000);
  },
  methods: {
    async getNotifications() {
      const { data: { data } } = await axios.get('/notifications/widget');

      this.notifications = data.last_notifications;
      this.countNotification = data.new_count;
    },
    goTo({ id, entity, entity_id }) {
      const route = goToNotificationRoute(entity, entity_id);
      if (route) {
        if (this.$route?.name !== route.name && this.$route?.params !== route.params) {
          this.$router.push(route);
        }

        axios.post('/notifications/read', {
          ids: [id],
        });

        this.hideDropDown();
      }

      this.getNotifications();
    },
    hideDropDown() {
      this.$refs.dropDownRef.hide();
    },
  },
};
</script>
