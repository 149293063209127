export default [
  {
    title: 'home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'hotSales',
    icon: 'DownloadIcon',
    route: {
      name: 'trading-open-offers-list',
      params: { param: 'open' },
    },
  },
  {
    title: 'requests',
    icon: 'UploadIcon',
    route: {
      name: 'trading-requests-list',
      params: { param: 'own' },
    },
    children: [
      {
        title: 'createNew',
        route: {
          name: 'trading-requests-add',
          params: { id: 'new' },
        },
      },
      {
        title: 'myRequests',
        route: {
          name: 'trading-requests-list',
          params: { param: 'own' },
        },
      },
    ],
  },
  {
    title: 'myDeals',
    route: {
      name: 'trading-deals-list',
      params: { param: 'all' },
    },
    icon: 'ShoppingBagIcon',
  },
  {
    title: 'documents',
    route: '',
    disabled: true,
    icon: 'FileTextIcon',
  },
  {
    title: 'chat',
    route: '',
    disabled: true,
    icon: 'MessageCircleIcon',
  },
  {
    title: 'notifications',
    route: 'apps-notifications',
    icon: 'BellIcon',
  },
  {
    title: 'myAccount',
    route: 'account-settings',
    icon: 'SettingsIcon',
  },
  {
    title: 'helpCenter',
    route: '',
    icon: 'HelpCircleIcon',
    disabled: true,
  },
];
