<template>
  <b-card no-body>
    <b-card-header>
      <div class="card-header">
        <h4>
          {{ title }}
        </h4>
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            v-if="showInputFileTag"
            ref="refInputEl"
            type="file"
            class="d-none"
            accept=".xlsx,.xls,.csv"
            @input="handleCsvUploaded"
          />
          <span class="d-none d-sm-inline">
            {{ $t('importFile') }}
          </span>
        </b-button>
      </div>
    </b-card-header>
    <hr class="divider" />

    <b-card-body class="p-0">
      <div v-if="draggableProducts.length">
        <hr class="divider" />
        <draggable
          v-model="draggableProducts"
          class="p-2"
          :options="{disabled : isProductUpdateActive}"
        >
          <trading-product-item
            v-for="product in draggableProducts"
            :key="product.id"
            :product="product"
            @remove="removeProduct"
            @update-start="startProductUpdating"
          >
            <template
              v-if="isProductUpdateActive && productToUpdate.id === product.id"
              slot="product-edit"
            >
              <trading-requests-new-product
                :is-existed-product="isProductUpdateActive"
                :is-product-from-item="isProductFromItem"
                :product-to-edit="productToUpdate"
                :is-save-btn-disabled="addBtnDisabled"
                :created-products-indexes="createdProductsIndexes"
                @create-product="createProduct"
                @update-product="updateProduct"
                @remove-product="removeProduct"
              />
            </template>
          </trading-product-item>
        </draggable>
      </div>
      <div
        v-if="showProductEditing"
      >
        <hr class="divider" />
        <div class="p-2">
          <trading-requests-new-product
            :product-to-edit="productToUpdate"
            :is-save-btn-disabled="addBtnDisabled"
            @create-product="createProduct"
            @update-product="updateProduct"
            @close-editing="closeEditing"
            @remove-product="removeProduct"
          />
        </div>
      </div>
      <hr class="divider" />
      <div class="p-2">
        <b-button
          variant="primary"
          :disabled="addBtnDisabled"
          @click="addProduct"
        >
          {{ $t('addItem') }}
        </b-button>
      </div>
    </b-card-body>

    <import-csv
      v-if="modalVisible"
      :title="title"
      :mode="'request'"
      :import-mode="'itemsForRequest'"
      :excel-rows-full-received="excelRowsFull"
      @close-modal="closeImportFileModal"
      @update-products-all="updateProductsAll"
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BButton,
} from 'bootstrap-vue';
import TradingProductItem from '@/views/trading/trading-add-item/TradingProductItem.vue';
import Ripple from 'vue-ripple-directive';
import TradingRequestsNewProduct from '@/views/trading/trading-add-item/requests/TradingRequestsNewProduct.vue';
import draggable from 'vuedraggable';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import i18n from '@/libs/i18n';
import { CERTIFICATE } from '@/constants/documentTypes';
import readXlsxFile from 'read-excel-file';
import 'vue-good-table/dist/vue-good-table.css';
import ImportCsv from '@/views/modals/ImportCsv.vue';

export default {
  components: {
    BCard,
    BButton,
    BCardBody,
    BCardHeader,
    TradingProductItem,
    TradingRequestsNewProduct,
    draggable,
    ImportCsv,
  },
  directives: {
    Ripple,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    products: {
      type: Array,
      default: (() => []),
    },
    addBtnDisabled: {
      type: Boolean,
      default: false,
    },
    isProductFromItem: {
      type: Boolean,
      default: false,
    },
    createdProductsIndexes: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    newItem: null,
    isProductUpdateActive: false,
    productToUpdate: null,
    showProductEditing: false,
    selectedCategory: null,
    modalVisible: false,
    showInputFileTag: true,
    excelRowsFull: [],
  }),
  computed: {
    draggableProducts() {
      return this.products;
    },
  },
  async created() {
    if (this.$route.params.id && this.$route.params.id === 'new') {
      this.showProductEditing = true;
    }
  },
  methods: {
    createProduct(data) {
      this.$emit('update-products', {
        data: !data.itemIndexToRemove ? [...this.products, data.data] : [...this.products, data.data]
          .filter(el => el.index !== data.itemIndexToRemove || (el.index === data.itemIndexToRemove && !el.isNeedSave)),
        needPush: true,
      });
      this.showProductEditing = false;
      this.isProductUpdateActive = false;
    },
    updateProduct(data) {
      const productsWithoutUpdated = this.products.filter(product => product.id !== data.id);
      this.$emit('update-products', { data: [...productsWithoutUpdated, data] });
      this.isProductUpdateActive = false;
    },
    updateProductsAll(updatedProducts) {
      const updatedIds = updatedProducts.map(updatedProduct => updatedProduct.id);
      const productsWithoutUpdated = this.products.filter(product => !updatedIds.includes(product.id));
      this.$emit('update-products', { data: [...productsWithoutUpdated, ...updatedProducts] });
      this.isProductUpdateActive = false;
      this.showProductEditing = false;
      this.productToUpdate = null;
    },
    removeProduct(id) {
      if (id) {
        const updatedProducts = this.products.filter(product => product.id !== id);
        this.$emit('update-products', { data: updatedProducts });
      } else {
        this.closeEditing();
      }
    },
    startProductUpdating(id) {
      if (this.isProductUpdateActive && this.productToUpdate && id === this.productToUpdate.id) {
        this.isProductUpdateActive = false;
        this.productToUpdate = null;
        return;
      }
      this.isProductUpdateActive = true;
      this.showProductEditing = false;
      this.productToUpdate = this.products.find(item => item.id === id);
    },
    closeEditing() {
      this.showProductEditing = false;
      this.isProductUpdateActive = false;
      this.productToUpdate = null;
    },
    addProduct() {
      this.closeEditing();
      this.showProductEditing = true;
    },
    async handleCsvUploaded() {
      // here - uploadDocuments()

      const xlsxFile = this.$refs.refInputEl.files ? this.$refs.refInputEl.files[0] : null;

      this.excelRowsFull = await readXlsxFile(xlsxFile);

      this.modalVisible = true;
    },
    async uploadDocuments() {
      const documentsToSend = Array.from(this.$refs.refInputEl.files);

      if (documentsToSend.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('documentNotSupported'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });

        return;
      }

      const newForm = new FormData();
      newForm.append('type', CERTIFICATE);
      documentsToSend.forEach(document => newForm.append('documents[]', document));
      const { data } = await this.$http.post('/documents', newForm);
      // this.documents = [...this.documents, ...data.data.documents];
    },
    closeImportFileModal() {
      this.modalVisible = false;

      this.showInputFileTag = false;

      this.$nextTick(() => {
        this.showInputFileTag = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../@core/scss/base/core/colors/palette-variables';
@import 'vue-good-table/dist/vue-good-table';

.divider {
  margin: 0;
}

.card-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.form-select {
  width: fit-content;
}

.text-style {
  color: #4e5154 !important;
}

.custom-class {
  background-color: #1ab7ea !important;
}

</style>
