<template>
  <div>
    <b-card
      class="product-item d-flex justify-content-between pa-2 mb-2 cursor-pointer"
      @click="editProduct"
    >
      <div class="d-flex justify-content-between align-items-start">
        <div class="d-flex w-100">
          <div class="product-item-image d-flex justify-content-center p-1 align-items-center">
            <b-img
              height="45"
              :src="productImage"
              @click.stop="selectImage"
            />
          </div>
          <div class="d-flex ml-2 flex-column justify-content-between w-100">
            <div class="d-flex align-items-center w-100">
              <h4 class="mb-0 mr-2 text-bold">
                {{ product.name }}
              </h4>
              <div class="d-flex justify-content-between product-badges">
                <b-badge
                  v-if="product.count"
                  variant="primary"
                >
                  {{ product.count }} {{ $t('pcs') }}
                </b-badge>
                <b-badge
                  v-if="!product.available && product.available !== null"
                  class="mr-1"
                  variant="light-danger"
                >
                  {{ $t('notAvailable') }}
                </b-badge>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <span
                v-if="product.group && product.group.name"
                class="mb-0 mr-2 text-bold"
              >
                {{ product.group.name }}
              </span>
              <span class="text-muted">
                {{ product.index }}
              </span>
            </div>
          </div>
        </div>
        <div
          v-if="product.isNeedSave"
          class="not-saved-div"
        >
          <feather-icon icon="EditIcon" />
          <span class="align-middle ml-50">
            {{ $t('notSaved') }}
          </span>
        </div>
        <b-dropdown
          v-if="!isPreviewMode"
          variant="link"
          toggle-class="p-0"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item
            @click.stop="editProduct"
          >
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">
              {{ $t('edit') }}
            </span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="!(isSupplier && onRequest)"
            @click.stop="deleteProductClick"
          >
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">
              {{ $t('delete') }}
            </span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="d-flex justify-content-between align-items-start">
        <div class="d-flex w-100 container">
          <div
            v-for="(document, index) in getDocuments()"
            :key="index"
            class="product-new-image-wrapper"
          >
            <feather-icon
              size="32"
              icon="EyeIcon"
              class="cursor-pointer product-new-image-show"
              @click="openDocument(document)"
            />
            <b-img
              class="product-new-image"
              :src="getDocumentImageUrl(document)"
              width="70"
              height="50px"
              rounded
            />
          </div>
        </div>
      </div>
    </b-card>
    <b-modal
      v-if="selectedImage"
      id="modal-center"
      v-model="imagePopup"
      :hide-footer="true"
      size="lg"
      centered
      :title="selectedImage.name"
    >
      <div>
        <div class="d-flex justify-content-between image-popup-controls">
          <b-button
            size="sm"
            @click="showPrev"
          >
            <feather-icon
              icon="ArrowLeftIcon"
              size="18"
            />
          </b-button>
          <b-button
            size="sm"
            @click="showNext"
          >
            <feather-icon
              icon="ArrowRightIcon"
              size="18"
            />
          </b-button>
        </div>
        <b-img
          class="w-100"
          :src="selectedImage.url"
        />
      </div>
    </b-modal>

    <slot name="product-edit" />

    <confirm-modal
      :modal-visible="removeConfirmVisible"
      :modal-message="String($t('removeProductConfirmMessage', {name: product.name}))"
      :ok-text="String($t('yesRemove'))"
      :cancel-text="String($t('noCancel'))"
      @modal-hidden="removeConfirmVisible = false"
      @yes-clicked="removeProduct"
      @no-clicked="removeConfirmVisible = false"
    />
  </div>
</template>

<script>
import {
  BCard, BBadge, BImg, BDropdown, BDropdownItem, BModal, BButton,
} from 'bootstrap-vue';
import confirmModal from '@/views/apps/components/modals/confirmModal.vue';

const placeholder = require('@/assets/images/placeholders/product-placeholder.svg');

export default {
  components: {
    confirmModal,
    BCard,
    BBadge,
    BImg,
    BDropdown,
    BModal,
    BButton,
    BDropdownItem,
  },
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    onRequest: {
      type: Boolean,
      default: false,
    },
    isPreviewMode: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    imagePopup: true,
    selectedImage: null,
    removeConfirmVisible: false,
  }),
  computed: {
    productImage() {
      if (this.product?.images?.length) {
        return this.product.images[0].url;
      }
      if (this.product?.documents?.length) {
        return this.product.documents[0].url;
      }
      return placeholder;
    },
    isSupplier() {
      return this.$store.getters['profile/isSupplierOnly'];
    },
  },
  methods: {
    removeProduct() {
      this.$emit('remove', this.product.id);
    },
    editProduct() {
      this.$emit('update-start', this.product.id);
    },
    getImages() {
      return [...this.product.images] || [];
    },
    getDocuments() {
      return [...this.product.documents] || [];
    },
    selectImage() {
      [this.selectedImage] = this.getImages();
      this.imagePopup = true;
    },
    showPrev() {
      const images = this.getImages();
      const imageId = images.findIndex(item => item.id === this.selectedImage.id) - 1;
      if (images[imageId]) {
        this.selectedImage = images[imageId];
      }
    },
    showNext() {
      const images = this.getImages();
      const imageId = images.findIndex(item => item.id === this.selectedImage.id) + 1;
      if (images[imageId]) {
        this.selectedImage = images[imageId];
      }
    },
    openDocument(document) {
      window.open(document.url, '_blank');
    },
    getDocumentImageUrl(document) {
      if (document.url) {
        if (document.url.match('(png|jpg|jpeg)')) {
          return document.url;
        }

        if (document.url.match('pdf')) {
          return require('@/assets/images/placeholders/pdf-placeholder.svg');
        }
      }

      return require('@/assets/images/placeholders/document-placeholder.svg');
    },
    deleteProductClick() {
      if (this.product.isNeedSave) {
        this.removeConfirmVisible = true;
      } else {
        this.removeProduct();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../@core/scss/base/core/colors/palette-variables';
@import 'src/@core/scss/base/components/variables-dark';
@import "src/@core/scss/base/pages/app-invoice.scss";

.image-popup-controls {
  position: absolute;
  top: calc(50% - 15px);
  padding: 0 10px;
  width: calc(100% - 40px);
}

.product-badges {
  flex: 1;
}
.product-item {
  background-color: $product-details-bg;
  &-image {
    background-color: $product-details-inner-bg;
    border-radius: 5px;
    max-height: 100px;
  }
  .text-bold {
    font-weight: 600;
  }
}

.divider {
  margin: 0;
}

.container {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.product-new-image-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: .5rem;
  .product-new-image-close {
    color: #b10d0d;
    opacity: 0;
    transition: all .4s ease;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
  .product-new-image-show {
    color: #6559f2;
    opacity: 0;
    transition: all .4s ease;
    position: absolute;
    z-index: 1;
  }
  .product-new-image {
    position: relative;
    user-select: none;
  }
  &:hover {
    .product-new-image-close, .product-new-image-show {
      opacity: 1;
      z-index: 100;
    }

    .product-new-image {
      opacity: 0.6;
    }
  }
}

.not-saved-div {
  width: 130px;
  color: red;
}
</style>

<style lang="scss">
@import '../../../@core/scss/base/core/colors/palette-variables';
@import 'src/@core/scss/base/components/variables-dark';
.dark-layout {
  .product-item {
    background-color: $theme-dark-body-bg;
  }
}
</style>
