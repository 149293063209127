<template>
  <b-card
    no-body
    :class="{'open': visible}"
    class="collapse-icon"
  >
    <b-card-header
      :class="{'collapsed': !visible}"
      :aria-expanded="visible ? 'true' : 'false'"
      aria-controls="transactions-collapse"
      role="tab"
      data-toggle="collapse"
      @click="updateVisible(!visible)"
    >
      <h4 class="d-inline-block mr-1 mb-0">
        {{ title }}
      </h4>
    </b-card-header>
    <b-collapse
      id="transactions-collapse"
      v-model="visible"
      role="tabpanel"
      accordion
    >
      <hr class="divider m-0" />
      <b-card-body>
        <div class="d-flex justify-content-between">
          <span>{{ $t('transactionsChain') }}</span>
        </div>
        <transactions-chain-admin
          :show-save="offer.status !== 4"
          :is-offer="true"
          :button-text="String($t('saveChainAndVerifyOffer'))"
          :transactions="transactions"
          :is-offer-editable="offer.can_edit"
          @add-new="addNewTransactions"
          @remove-item="removeTransactions"
        />
        <b-media
          v-if="offer.status === 4"
          vertical-align="center"
          class="mt-1"
        >
          <div class="d-flex align-items-center">
            <template>
              <b-avatar
                size="32"
                variant="light-success"
              >
                <feather-icon
                  icon="CheckCircleIcon"
                />
              </b-avatar>
            </template>
            <h6 class="ml-1 mb-0">
              {{ $t('verified') }}
            </h6>
          </div>
        </b-media>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCollapse, BCard, BCardBody, BCardHeader, BMedia, BAvatar,
} from 'bootstrap-vue';
import TransactionsChainAdmin from '@/views/trading/trading-deals/transactions/TransactionsChainAdmin.vue';

export default {
  components: {
    BCollapse,
    BCard,
    BCardBody,
    BCardHeader,
    BMedia,
    BAvatar,
    TransactionsChainAdmin,
  },
  props: {
    title: {},
    offer: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      visible: true,
    };
  },
  computed: {
    transactions() {
      return this.$store.state.offers.legals || [];
    },
  },
  methods: {
    updateVisible(val = true) {
      this.visible = val;
      this.$emit('visible', val);
    },
    async addNewTransactions({ id }) {
      const newItem = {
        legal_id: id,
        price: this.offer.price,
        margin: 0,
      };

      await this.$store.dispatch('offers/createNewOfferLegal', { id: this.offer.id, payload: newItem });
    },
    removeTransactions(id) {
      this.$store.dispatch('offers/deleteOfferLegal', { id, offerId: this.offer.id });
    },
  },
};
</script>

<style lang="scss">
@import 'src/@core/scss/base/core/colors/palette-variables.scss';
@import "src/@core/scss/base/components/_variables-dark.scss";
.transaction-item {
  border: 1px solid $table-border-color;
  border-radius: 6px;
  transition: all .4s ease;
  &-add-new {
    border: 1px dashed $table-border-color;
  }
}
.dark-layout {
  .transaction-item {
    border:  1px solid $theme-dark-widget-bg;
    &-add-new {
      border: 1px dashed $theme-dark-widget-bg;
    }
  }
}
</style>
