export function goToNotificationRoute(entity, entityId) {
  switch (entity) {
    case 'offer':
      return { name: 'trading-offers-add', params: { id: String(entityId) } };
    case 'requestmodel':
      return { name: 'trading-requests-add', params: { id: String(entityId) } };
    case 'deal':
      return { name: 'trading-deals-add', params: { id: String(entityId) } };
    case 'user':
      return { name: 'apps-users-edit', params: { id: String(entityId) } };
    default:
      return null;
  }
}
