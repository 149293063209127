<template>
  <div>
    <b-table
      ref="refDealProducts"
      class="position-relative product-table"
      primary-key="id"
      empty-text="No matching records found"
      responsive
      outlined
      show-empty
      :items="changedProducts"
      :fields="headers"
      @row-clicked="rowClick"
    >
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="columnStyle(field)"
        />
      </template>
      <template #cell(item)="data">
        <div class="d-flex flex-column">
          <div class="d-flex">
            <div v-if="data.item.analog_index || data.item.analog_name">
              <b-badge
                class="mr-1"
                variant="light-warning"
              >
                {{ $t('analogue') }}
              </b-badge>
            </div>
            <div v-if="!data.item.available">
              <b-badge variant="light-danger">
                {{ $t('notAvailable') }}
              </b-badge>
            </div>
          </div>
          <b class="product-name">
            {{ data.item.analog_name || data.item.name }}
          </b>
          <small class="text-muted">
            {{ data.item.analog_index || data.item.index }}
          </small>
        </div>
      </template>

      <template #cell(vat)="data">
        <span v-if="data.item.vat">
          {{ data.item.vat }}%
        </span>
        <span v-else>
          -
        </span>
      </template>

      <template #cell(cost)="data">
        <span>
          {{ data.item.total_sum }}
        </span>
      </template>
      <template #cell(tnv)="data">
        <span>
          {{ data.item.tnv }}
        </span>
      </template>

      <template #cell(actions)="data">
        <div
          v-if="allowToEditProducts"
          class="text-nowrap"
        >
          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="editProduct(data.item)">
              <feather-icon icon="EditIcon" />
              <span
                class="align-middle ml-50"
              >
                {{ $t('edit') }}
              </span>
            </b-dropdown-item>
            <b-dropdown-item @click="removeProduct(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">
                {{ $t('delete') }}
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>

      <template #row-details="data">
        <b-card no-body>
          <trading-offers-new-product
            v-if="productsToEdit.includes(data.item.id)"
            :is-existed-product="true"
            :product-to-edit="data.item"
            :currency="currency"
            @remove-product="cancelProductEditing"
            @update-product="updateProduct"
          />
          <b-row
            v-else
            class="product-table-details"
          >
            <b-col
              cols="4"
              class="d-flex flex-wrap"
            >
              <div
                v-for="image in getImagesAndCertificates(data.item)"
                :key="image.id"
                class="mr-1 d-flex align-items-center justify-content-center"
              >
                <div v-if="image.document_name">
                  <b-tooltip
                    :target="`document-${image.id}`"
                    triggers="hover"
                  >
                    {{ image.name }}
                  </b-tooltip>
                  <a
                    target="_blank"
                    :href="image.url"
                  >
                    <feather-icon
                      :id="`document-${image.id}`"
                      icon="FileTextIcon"
                      size="36"
                    />
                  </a>
                </div>
                <b-img
                  v-else
                  :src="image.url"
                  width="62px"
                  rounded
                  @click="selectImage(data.item, image)"
                />
              </div>
            </b-col>
            <b-col
              cols="2"
              class="pl-3"
            >
              <b>{{ $t('packing') }}</b>
            </b-col>
            <b-col
              cols="2"
            >
              <div class="d-flex flex-column">
                <span>{{ $t('qty') }} (@10):</span>
                <span>{{ $t('netWeight') }}:</span>
              </div>
            </b-col>
            <b-col
              cols="1"
            >
              <div class="d-flex flex-column">
                <span>{{ data.item.packing_qty || 0 }} {{ $t('pcs') }}</span>
                <span>{{ data.item.packing_net || 0 }} {{ $t('kgs') }}</span>
              </div>
            </b-col>
            <b-col
              cols="2"
            >
              <div class="d-flex flex-column">
                <span>{{ $t('grossWeight') }}:</span>
                <span>Meas' Mt:</span>
              </div>
            </b-col>
            <b-col cols="1">
              <div class="d-flex flex-column">
                <span>{{ data.item.packing_gross || 0 }} {{ $t('pcs') }}</span>
                <span>{{ data.item.packing_meas || 0 }} {{ $t('cuFt') }}</span>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </template>
    </b-table>
    <b-modal
      v-if="selectedImage"
      id="modal-center"
      v-model="imagePopup"
      :hide-footer="true"
      size="lg"
      centered
      :title="selectedImage.name"
    >
      <div>
        <div class="d-flex justify-content-between image-popup-controls">
          <b-button
            size="sm"
            @click="showPrev"
          >
            <feather-icon
              icon="ArrowLeftIcon"
              size="18"
            />
          </b-button>
          <b-button
            size="sm"
            @click="showNext"
          >
            <feather-icon
              icon="ArrowRightIcon"
              size="18"
            />
          </b-button>
        </div>
        <b-img
          class="w-100"
          :src="selectedImage.url"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>

import {
  BCard, BRow, BCol, BButton, BTable, BDropdownItem, BDropdown, BModal, BImg, BBadge, BTooltip,
} from 'bootstrap-vue';
import { getCurrencySymbol } from '@/constants/currency';
import TradingOffersNewProduct from '@/views/trading/trading-add-item/offers/TradingOffersNewProduct.vue';

export default {
  components: {
    TradingOffersNewProduct,
    BCard,
    BRow,
    BCol,
    BTable,
    BDropdownItem,
    BDropdown,
    BModal,
    BImg,
    BBadge,
    BButton,
    BTooltip,
  },
  props: {
    products: {
      default: (() => []),
      required: false,
      type: Array,
    },
    allowToEditProducts: {
      type: Boolean,
      default: true,
    },
    currency: {
      type: Number,
      default: 0,
    },
    margin: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      expanded_rows: {},
      visible: true,
      imagePopup: false,
      selectedImage: null,
      selectedProduct: null,
      productsToEdit: [],
    };
  },
  computed: {
    changedProducts() {
      const { expanded_rows, products } = this;
      return products.map(item => ({
        ...item,
        _showDetails: expanded_rows[item.id],
      }));
    },
    headers() {
      return [
        { key: 'item', label: this.$t('item'), sortable: true },
        { key: 'count', label: this.$t('qty'), sortable: true },
        { key: 'price', label: `${this.$t('price')}, ${getCurrencySymbol(this.currency)}`, sortable: true },
        { key: 'vat', label: this.$t('vat'), sortable: true },
        { key: 'cost', label: `${this.$t('cost')}, ${getCurrencySymbol(this.currency)}`, sortable: true },
        { key: 'tnv', label: this.$t('tnVed'), sortable: true },
        { key: 'actions', label: '' },
      ];
    },
  },
  methods: {
    columnStyle(field) {
      if (field.key === 'item') {
        return { width: '35%' };
      }
      if (field.key === 'count' || field.key === 'price') {
        return { width: '10%' };
      }
      if (field.key === 'cost') {
        return { width: '15%' };
      }
      if (field.key === 'tnved') {
        return { width: '25%' };
      }
      if (field.key === 'actions') {
        return { width: '5%' };
      }
      return {};
    },
    getImagesAndCertificates(item) {
      return [...item.images, ...item.documents] || [];
    },
    selectImage(item, image) {
      this.selectedImage = image;
      this.selectedProduct = item;
      this.imagePopup = true;
    },
    showPrev() {
      const { images } = this.selectedProduct;
      const imageId = images.findIndex(item => item.id === this.selectedImage.id) - 1;
      if (images[imageId]) {
        this.selectedImage = images[imageId];
      }
    },
    showNext() {
      const { images } = this.selectedProduct;
      const imageId = images.findIndex(item => item.id === this.selectedImage.id) + 1;
      if (images[imageId]) {
        this.selectedImage = images[imageId];
      }
    },
    rowClick(item) {
      const { expanded_rows } = this;
      const { id } = item;

      this.$set(expanded_rows, id, !expanded_rows[id]);
      if (!expanded_rows[id]) {
        this.cancelProductEditing(id);
      }
    },
    removeProduct(id) {
      const updatedProducts = this.products.filter(product => product.id !== id);
      this.$emit('update-products', updatedProducts);
    },
    updateProduct(product) {
      const productToUpdate = this.products.find(item => item.id === product.id);
      Object.assign(productToUpdate, product);
      this.$emit('update-products', this.products);
      this.cancelProductEditing(product.id);
    },
    cancelProductEditing(id) {
      const index = this.productsToEdit.indexOf(id);
      if (index > -1) {
        this.productsToEdit.splice(index, 1);
      }
    },
    editProduct(item) {
      this.productsToEdit.push(item.id);
      if (!item._showDetails) {
        this.rowClick(item);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.product-table {
  min-height: 110px;
}
.image-popup-controls {
  position: absolute;
  top: calc(50% - 15px);
  padding: 0 10px;
  width: calc(100% - 40px);
}
</style>
<style lang="scss">
@import '../../@core/scss/base/core/colors/palette-variables';
@import 'src/@core/scss/base/core/colors/palette-variables.scss';
@import "src/@core/scss/base/components/_variables-dark.scss";

.b-table-details, .product-table-details {
  background-color: $table-head-bg;
}
.dark-layout {
  .b-table-details, .product-table-details {
    background-color: $theme-dark-table-header-bg;
  }
}
.divider {
  margin: 0;
}

.product-table {
  .product-name {
    transition: all .2s ease;
  }
  .b-table-has-details {
    .product-name {
      color: $primary;
    }
  }
}

</style>
