<template>
  <b-overlay
    class="overlay"
    :show="isLoading"
    rounded="sm"
    no-center
  >
    <template #overlay>
      <div class="loader-container">
        <b-spinner />
      </div>
    </template>

    <section
      v-if="!isLoading"
      class="request-add-wrapper"
    >
      <b-row
        class="request-add"
      >
        <!-- Col: Left (Offer Container) -->
        <b-col
          cols="12"
          xl="9"
          md="8"
        >
          <trading-new-country
            :header="!isNewMode ? $t('editRequest', { requestId: requestIdProp || requestId }) : $t('newRequest')"
            :title.sync="request.title"
            :country-id.sync="request.country_id"
            :budget.sync="request.budget"
          >
            <template #status>
              <b-badge
                :variant="resolveRequestStatus(status).variant"
                class="px-1"
              >
                {{ resolveRequestStatus(status).status }}
              </b-badge>
            </template>
          </trading-new-country>

          <trading-requests-new-item
            :products="request.products"
            :add-btn-disabled="!isNewMode && !request.can_edit"
            :is-product-from-item="!!itemsForRequestProp.length"
            :created-products-indexes="createdProductsIndexes"
            :title="$t('products')"
            @update-products="updateProducts"
          />
        </b-col>

        <!-- Right Col: Card -->
        <b-col
          cols="12"
          md="4"
          xl="3"
        >
          <trading-user-select-by-manager
            v-if="isAllowToSelectBuyer"
            :title="$t('buyer')"
            :header-label="$t('selectBuyer')"
            :options="optionsForSelectBuyer"
            :user.sync="request.legal_id"
          />
          <trading-new-request-type
            :title="$t('requestType')"
            :selected-type.sync="request.type"
            :selected-type-term.sync="request.type_term"
          />
          <trading-new-validity-period
            :title="$t('desiredRequestTerm')"
            :date.sync="validDeliveryDateValue"
            @update-date="updateDeliveryTerm"
          />
          <trading-new-save-aside
            item-type="request"
            :is-new-mode="isNewMode"
            :item-id="request.id"
            :message.sync="request.note"
            :is-save-disabled.sync="isSaveDisabled || !request.products.filter(product => !product.isNeedSave).length"
            :is-item-editable="isNewMode || (!isNewMode && !request.can_edit)"
            :is-item-removable="request.can_delete"
            :spinning-buttons-states="spinningButtonsStates"
            @save="save"
            @publish="publish"
            @update="setNote"
            @cancel="cancel"
            @delete="deleteRequest"
          />
          <b-card v-if="isAllowVerify">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mb-1"
              block
              @click="verify"
            >
              {{ $t('verify') }}
            </b-button>
          </b-card>
          <b-card v-if="isAllowCreateOffer">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              :disabled="isOfferCreating"
              @click="createOffer"
            >
              {{ $t('createAnOffer') }}
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </b-overlay>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, BBadge, BOverlay, BSpinner,
} from 'bootstrap-vue';
import TradingNewCountry from '@/views/trading/TradingNewCountry.vue';
import TradingNewValidityPeriod from '@/views/trading/TradingNewValidityPeriod.vue';
import TradingNewSaveAside from '@/views/trading/TradingNewSaveAside.vue';
import TradingRequestsNewItem from '@/views/trading/trading-add-item/requests/TradingRequestsNewItem.vue';
import TradingNewRequestType from '@/views/trading/TradingNewRequestType.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import TradingUserSelectByManager from '@/views/trading/TradingUserSelectByManager.vue';
import Ripple from 'vue-ripple-directive';
import axios from '@/libs/axios';
import router from '@/router';
import TradingOffersNewItem from '@/views/trading/trading-add-item/offers/TradingOffersNewItem.vue';

export default {
  components: {
    TradingOffersNewItem,
    BSpinner,
    BOverlay,
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,
    TradingNewCountry,
    TradingNewValidityPeriod,
    TradingNewSaveAside,
    TradingRequestsNewItem,
    TradingNewRequestType,
    TradingUserSelectByManager,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: {
    useMode: {
      type: String,
      default: 'createOrEdit', // can be 'createAndAddProducts', 'editAndAddProducts'
    },
    isNewModeProp: {
      type: Boolean,
      default: false,
    },
    requestIdProp: {
      type: Number,
      default: null,
    },
    itemsForRequestProp: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    request: {
      note: '',
      id: '',
      title: '',
      delivery_datetime: null,
      delivery_type: null,
      type: null,
      type_term: null,
      warranty: null,
      legal_id: '',
      created_at: null,
      updated_at: null,
      country_id: '',
      products: [],
      budget: '',
      can_delete: null,
    },
    isOfferCreating: false,
    requestId: '',
    isLoading: false,
    spinningButtonsStates: {
      okButton: false,
      saveButton: false,
      saveAndPublishButton: false,
    },
    createdProductsIndexes: [],
    validDeliveryDateValue: null,
  }),
  computed: {
    isNewMode() {
      return this.isNewModeProp || this.$route.params.id === 'new';
    },
    isAllowToSelectBuyer() {
      return this.$store.getters['profile/isAdmin'] && this.$store.state.requests.currentRequest.can_edit;
    },
    isAllowVerify() {
      return this.$store.getters['profile/isAdmin']
        && (this.request.status >= 1 && this.request.status < 4)
        && !this.isNewMode;
    },
    dataToSend() {
      const sendData = {
        note: this.request.note,
        title: this.request.title,
        product_ids: this.request.products.filter(item => !item.isNeedSave).map(item => item.id),
        country_id: this.request.country_id,
        owned_by: this.$store.state.profile.profile.id,
        delivery_datetime: this.request.delivery_datetime,
        type: this.request.type,
        budget: this.request.budget,
        type_term: this.request.type_term,
      };
      if (this.$store.getters['profile/isAdmin'] && this.request.legal_id) {
        sendData.legal_id = this.request.legal_id;
      }
      return sendData;
    },
    isAllowCreateOffer() {
      if (this.isNewMode) {
        return false;
      }

      const { currentRequest } = this.$store.state.requests;

      const { profile } = this.$store.state.profile;
      if (profile && profile.id === currentRequest?.user?.id) {
        return false;
      }

      return currentRequest.published && currentRequest.verified;
    },
    status() {
      return this.$store.state.requests.currentRequest?.status;
    },
    isSaveDisabled() {
      return !this.request.type || !this.request.type_term || (!this.isNewMode && !this.request.can_edit);
    },
    optionsForSelectBuyer() {
      const options = [];

      if (this.isAllowToSelectBuyer && this.$store.state.profile?.profile?.company?.id) {
        options.push({
          id: this.$store.state.profile.profile.company.id, // need change to profile.id (and in another places)
          company_name: `${this.$store.state.profile.profile.company.company_name} (Admin)`,
        });
      }

      return [...options, ...this.$store.state.offers.legalBuyers];
    },
    isUserAdmin() {
      return this.$store.getters['profile/isAdmin'];
    },
    isSupplierOnly() {
      return this.$store.getters['profile/isSupplierOnly'];
    },
  },
  // async created() {
  //     await this.initOffer();
  //     if (this.$store.getters['profile/isAdmin']) {
  //         await this.$store.dispatch('offers/fetchLegalSuppliers');
  //     }
  // },
  watch: {
    // '$route.params.id': {
    //   immediate: true,
    //   handler: 'fetchRequest',
    // },
    isAllowToSelectBuyer(value) {
      if (value) this.$store.dispatch('offers/fetchLegalBuyers');
    },
  },
  async mounted() {
    await this.fetchRequest();
  },
  methods: {
    async fetchRequest(skipLoader = false) {
      if (!skipLoader) this.isLoading = true;

      try {
        if ((!this.isNewModeProp && this.requestIdProp) || (this.$route.params.id && this.$route.params.id !== 'new')) {
          await this.$store.dispatch('requests/fetchRequestById', this.requestIdProp || this.$route.params.id);
          this.request = {
            ...this.request,
            ...this.$store.state.requests.currentRequest,
            country_id: this.$store.state.requests.currentRequest?.country?.id || '',
            legal_id: this.$store.state.requests.currentRequest?.ownedBy?.company_id || '',
          };

          this.validDeliveryDateValue = this.request.delivery_datetime;

          this.requestId = `${this.request.id}`;

          if (this.itemsForRequestProp.length) {
            const copyProducts = JSON.parse(JSON.stringify(this.request.products));

            const arr = [...copyProducts];

            this.itemsForRequestProp.forEach(itemForRequestProp => {
              arr.push({
                isNeedSave: true,
                analog_index: null,
                analog_name: null,
                category: null,
                vat: null,
                price: 0,
                image_ids: [],
                document_ids: [],
                packing_qty: null,
                packing_net: null,
                packing_gross: null,
                packing_meas: null,
                delivery_type: 'retail',
                country: 0,
                total_sum: 0,
                warranty: 0,
                tnved_codes: null,
                available: true,
                images: [],
                documents: [],
                ...itemForRequestProp,
              });
            });

            this.updateProducts({ data: arr });
          }
        }
        if (this.isNewModeProp || (this.$route.params.id && this.$route.params.id === 'new')) {
          this.requestId = this.$t('new');

          if (this.itemsForRequestProp.length) {
            const arr = [];

            this.itemsForRequestProp.forEach(itemForRequestProp => {
              arr.push({
                isNeedSave: true,
                analog_index: null,
                analog_name: null,
                category: null,
                vat: null,
                price: 0,
                image_ids: [],
                document_ids: [],
                packing_qty: null,
                packing_net: null,
                packing_gross: null,
                packing_meas: null,
                delivery_type: 'retail',
                country: 0,
                total_sum: 0,
                warranty: 0,
                tnved_codes: null,
                available: true,
                images: [],
                documents: [],
                ...itemForRequestProp,
              });
            });

            this.$nextTick(() => {
              this.updateProducts({ data: arr });
            });
          }
        }
        if (this.isAllowToSelectBuyer) {
          await this.$store.dispatch('offers/fetchLegalBuyers');
        }
      } catch {
        this.$router.go(-1);
      } finally {
        if (!skipLoader) this.isLoading = false;
      }
    },
    resolveRequestStatus(status) {
      if (!status) {
        return { variant: 'deal__not-published', status: this.$t('notPublished') };
      }
      if (status === 1) {
        return { variant: 'deal__equipment', status: this.$t('pendingVerification') };
      }
      if (status === 5) {
        return { variant: 'deal__accepted', status: this.i18n.t('deal') };
      }
      return { variant: 'deal__published', status: this.$t('published') };
    },
    updateDeliveryTerm(date) {
      this.request.delivery_datetime = date;
    },
    updateProducts(data) {
      if (data.needPush) {
        this.createdProductsIndexes.push(data.data[data.data.length - 1].id);
      }

      this.request = {
        ...this.request,
        products: data.data,
      };
    },
    async save(andPublishMode = false) {
      if (!andPublishMode) this.spinningButtonsStates.saveButton = true;

      if (!this.request.products.length) {
        return this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('addProductsError'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }

      if (this.isNewModeProp || this.$route.params.id === 'new') {
        const { data } = await this.$http.post('/requests', this.dataToSend);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('changesSaved'),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        });

        if (this.isNewModeProp) {
          this.$emit('close-requests-new');
        } else {
          await this.$router.push({ name: this.$router.currentRoute.name, params: { id: data.data.request.id } });

          await this.fetchRequest(true);
        }
      } else {
        await this.$store.dispatch('requests/updateRequest', {
          requestId: this.requestIdProp || this.$route.params.id,
          payload: this.dataToSend,
        });

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('changesSaved'),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        });
      }

      if (this.spinningButtonsStates.saveButton) this.spinningButtonsStates.saveButton = false;
    },
    async publish() {
      this.spinningButtonsStates.saveAndPublishButton = true;

      await this.save(true);
      const { data } = await this.$http.post(`/requests/publish/${this.requestIdProp || this.$route.params.id}`);
      await this.$store.commit('requests/SET_CURRENT_REQUESTS', data.data.request);

      this.spinningButtonsStates.saveAndPublishButton = false;
    },
    async verify() {
      await this.$http.post(`/requests/verify/${this.requestIdProp || this.$route.params.id}`);
      await this.$store.dispatch('requests/fetchRequestById', this.requestIdProp || this.$route.params.id);

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'The request verified successfully',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      });

      this.setRequest();
    },
    async createOffer() {
      this.isOfferCreating = true;

      try {
        const { data: { data } } = await this.$http.post(`/requests/by-offer/${this.requestIdProp || this.$route.params.id}`);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('offerFromRequestMessage', { id: this.requestIdProp || this.$route.params.id }),
            icon: 'EditIcon',
            variant: 'success',
          },
        });

        await this.$router.push({ name: 'trading-offers-add', params: { id: data.offer.id } });
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('somethingWentWrong', { msg: e.response.data.message }),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      } finally {
        this.isOfferCreating = false;
      }
    },
    cancel() {
      this.$router.go(-1);
    },
    setNote(data) {
      this.request.note = data;
    },
    setRequest() {
      this.request = {
        ...this.request,
        ...this.$store.state.requests.currentRequest,
        country_id: this.$store.state.requests.currentRequest?.country?.id || '',
        legal_id: this.$store.state.requests.currentRequest?.ownedBy?.company_id || '',
      };
    },
    async deleteRequest() {
      this.spinningButtonsStates.okButton = true;

      await axios.delete(`/v1/requests/${this.request.id}/delete`).then(async () => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('requestDeletedSuccessfully', { id: this.request.id }),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        });

        if (this.isNewModeProp || this.requestIdProp) {
          this.$emit('close-requests-new');
        } else {
          await router.push({
            name: 'trading-requests-list',
            params: { param: this.isUserAdmin || this.isSupplierOnly ? 'all' : 'own' },
          });
        }

        this.modalVisible = false;
      }).catch(e => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('somethingWentWrong', { msg: e.response.data.message }),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }).finally(() => {
        this.spinningButtonsStates.okButton = false;
      });
    },
  },
};
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped lang="scss">
.loader-container {
  color: #7367f0;
  margin-top: 38vh;
  margin-left: 38vw;
}
</style>
