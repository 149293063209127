<template>
  <div>
    <b-card>
      <b-card-body
        ref="form"
        class="repeater-form"
      >
        <b-row
          ref="row"
          class="pb-2"
        >
          <b-col cols="12">
            <div class="d-flex border rounded">
              <div class="d-flex flex-column w-100">
                <b-row class="flex-grow-1 pt-2 pl-2 pr-2">
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <b-form-group
                      :label="$t('productGroup')"
                      label-for="product-group"
                      class="mb-0"
                    >
                      <v-select
                        v-model="productToCreate.group_id"
                        :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                        :options="$store.state.products.groups"
                        :reduce="val => val.id"
                        :disabled="isDisableRequestFields"
                        :clearable="false"
                        label="name"
                        input-id="product-groups"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <b-form-group
                      :label="$t('productName')"
                      label-for="product-name"
                      class="mb-0"
                    >
                      <b-form-input
                        id="product-name"
                        v-model="productToCreate.name"
                        :disabled="isDisableRequestFields"
                        class="mb-2"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="8"
                    lg="4"
                  >
                    <b-form-group
                      :label="$t('partNumber')"
                      label-for="product-number"
                    >
                      <b-form-input
                        id="product-number"
                        :value="productToCreate.index"
                        :disabled="isDisableRequestFields"
                        class="mb-2"
                        @input="setProductNumber"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="8"
                    lg="4"
                  >
                    <b-form-group
                      :label="$t('ean')"
                      label-for="ean-code"
                    >
                      <b-form-input
                        id="ean-code"
                        v-model="productToCreate.ean"
                        type="number"
                        :disabled="isDisableRequestFields"
                        class="mb-2"
                        @focusin="inputNumberFocus('in', 'ean')"
                        @focusout="inputNumberFocus('out', 'ean')"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="8"
                    lg="4"
                  >
                    <b-form-group
                      :label="$t('tnVed')"
                      label-for="tn-ved"
                    >
                      <b-form-input
                        id="tn-ved"
                        v-model="productToCreate.tnv"
                        type="number"
                        :disabled="isDisableRequestFields"
                        class="mb-2"
                        @focusin="inputNumberFocus('in', 'tnv')"
                        @focusout="inputNumberFocus('out', 'tnv')"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="onRequest"
                    cols="12"
                    lg="12"
                    class="d-flex flex-column"
                  >
                    <div>
                      <b-button
                        variant="flat-primary mb-1"
                        @click="toggleAnalogBlock"
                      >
                        <span class="d-none d-sm-inline">
                          {{ isShowAnalogBlock ? 'Remove analogue' : 'Suggest analogue' }}
                        </span>
                      </b-button>
                      <b-button
                        variant="flat-secondary mb-1"
                        @click="disableProduct"
                      >
                        <span class="d-none d-sm-inline">
                          {{ $t('productNotAvailable') }}
                        </span>
                      </b-button>
                    </div>
                    <b-row v-if="isShowAnalogBlock">
                      <b-col
                        cols="12"
                        lg="6"
                      >
                        <b-form-group
                          label="Name of analog product"
                          label-for="analog-name"
                        >
                          <b-form-input
                            id="analog-name"
                            v-model="productToCreate.analog_name"
                            class="mb-2"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        lg="6"
                      >
                        <b-form-group
                          label="Part Number of the analogue product"
                          label-for="analog-index"
                        >
                          <b-form-input
                            id="analog-index"
                            :value="productToCreate.analog_index"
                            class="mb-2"
                            @input="setAnalogIndex"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12">
                    <b-form-textarea
                      v-model="productToCreate.note"
                      :disabled="isDisableRequestFields"
                      class="mb-2"
                      :placeholder="$t('description')"
                    />
                  </b-col>
                </b-row>
                <trading-new-packing
                  :product="productToCreate"
                  @update="updatePacking"
                />
                <div class="flex-grow-1 pb-2 pl-2 pr-2">
                  <b-form-radio-group
                    id="packing-type"
                    v-model="productToCreate.delivery_type"
                    :options="packingTypeOptions"
                    :disabled="isRadioDisabled"
                  />
                </div>
                <trading-new-total
                  :qty="productToCreate.count"
                  :price="productToCreate.price"
                  :vat="productToCreate.vat"
                  :currency="currency"
                  :disabled="isDisableRequestFields"
                  @update-price="updatePrice"
                  @update-qty="updateQty"
                  @update-vat="updateVat"
                />
                <b-row class="flex-grow-1 pb-2 pl-2 pr-2">
                  <b-col
                    cols="12"
                  >
                    <span>{{ $t('images') }}</span>
                    <div class="d-flex w-100 flex-wrap">
                      <div
                        v-for="(image, index) in images"
                        :key="index"
                        class="product-new-image-wrapper"
                      >
                        <feather-icon
                          size="24"
                          icon="XIcon"
                          class="cursor-pointer product-new-image-close"
                          :disabled="isDisableRequestFields"
                          @click="removeImage(index)"
                        />
                        <b-img
                          class="product-new-image"
                          :src="getDocumentImageUrl(image)"
                          width="100px"
                          height="70px"
                          rounded
                        />
                      </div>
                    </div>
                    <b-button
                      variant="flat-primary"
                      @click="$refs.refInputEl.click()"
                    >
                      <input
                        ref="refInputEl"
                        type="file"
                        class="d-none"
                        multiple="multiple"
                        accept="image/*"
                        @input="uploadImages"
                      />
                      <span class="d-none d-sm-inline">
                        {{ $t('addImage') }}
                      </span>
                      <feather-icon
                        icon="EditIcon"
                        class="d-inline d-sm-none"
                      />
                    </b-button>
                  </b-col>
                </b-row>
                <b-row class="flex-grow-1 pb-2 pl-2 pr-2">
                  <b-col
                    cols="12"
                  >
                    <span>{{ $t('certificatesPdfTitle') }}</span>
                    <div class="d-flex w-100 flex-wrap">
                      <div
                        v-for="(document, index) in documents"
                        :key="index"
                        class="product-new-image-wrapper"
                      >
                        <feather-icon
                          size="24"
                          icon="XIcon"
                          class="cursor-pointer product-new-image-close"
                          @click="removeDocument(index)"
                        />
                        <feather-icon
                          size="32"
                          icon="EyeIcon"
                          class="cursor-pointer product-new-image-show"
                          @click="openDocument(document)"
                        />
                        <b-img
                          class="product-new-image"
                          :src="getDocumentImageUrl(document)"
                          width="100px"
                          height="70px"
                          rounded
                        />
                      </div>
                    </div>
                    <b-button
                      variant="flat-primary"
                      @click="$refs.refInputDocumentEl.click()"
                    >
                      <input
                        ref="refInputDocumentEl"
                        type="file"
                        class="d-none"
                        multiple="multiple"
                        accept="image/*,application/pdf"
                        @input="uploadDocuments"
                      />
                      <span class="d-none d-sm-inline">
                        {{ $t('attachCertificate') }}
                      </span>
                      <feather-icon
                        icon="EditIcon"
                        class="d-inline d-sm-none"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </div>
              <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                <feather-icon
                  size="16"
                  icon="XIcon"
                  class="cursor-pointer"
                  @click="removeIconClick"
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card-body>

      <b-tooltip
        v-if="notFilledRequiredFields.length"
        target="saveButton"
        triggers="hover"
      >
        <span>{{ $t('fillRequiredFieldsForProduct') }}:</span>
        <div
          v-for="(field, index) in notFilledRequiredFields"
          :key="index"
        >
          <span>
            - {{ productFieldsData[field] }}
          </span>
        </div>
      </b-tooltip>
      <div
        id="saveButton"
        class="tooltip-button"
      >
        <b-button
          class="save-item-btn"
          variant="primary"
          :disabled="!!notFilledRequiredFields.length"
          @click="saveProduct"
        >
          {{ $t('save') }}
        </b-button>
      </div>
    </b-card>

    <confirm-modal
      :modal-visible="removeConfirmVisible"
      :modal-message="String($t('removeProductConfirmMessage', {name: productToCreate.name}))"
      :ok-text="String($t('yesRemove'))"
      :cancel-text="String($t('noCancel'))"
      @modal-hidden="removeConfirmVisible = false"
      @yes-clicked="remove"
      @no-clicked="removeConfirmVisible = false"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BCardBody, BFormInput, BButton, BFormGroup, BFormRadioGroup, BImg, BFormTextarea, BTooltip,
} from 'bootstrap-vue';
import TradingNewPacking from '@/views/trading/trading-add-item/offers/TradingNewPacking.vue';
import TradingNewTotal from '@/views/trading/trading-add-item/TradingNewTotal.vue';
import vSelect from 'vue-select';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { CERTIFICATE } from '@/constants/documentTypes.js';
import i18n from '@/libs/i18n';
import confirmModal from '@/views/apps/components/modals/confirmModal.vue';
import productFieldsData from '@/views/data/productFieldsData';

export default {
  components: {
    BTooltip,
    confirmModal,
    BCard,
    BRow,
    BCol,
    BCardBody,
    BFormInput,
    BButton,
    BFormGroup,
    TradingNewPacking,
    BFormRadioGroup,
    TradingNewTotal,
    BFormTextarea,
    BImg,
    vSelect,
  },
  props: {
    isExistedProduct: {
      type: Boolean,
      default: false,
    },
    onRequest: {
      type: Boolean,
      default: false,
    },
    isProductFromItem: {
      type: Boolean,
      default: false,
    },
    productToEdit: {
      type: Object,
      default: () => {},
    },
    currency: {
      type: Number,
      default: 0,
    },
    createdProductsIndexes: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    images: [],
    documents: [],
    packingTypeOptions: [
      { text: 'RETAIL', value: 'retail' },
      { text: 'BULK', value: 'bulk' },
      { text: 'OEM', value: 'oem' },
    ],
    isShowAnalogBlock: false,
    productToCreate: {
      name: '',
      index: '',
      analog_index: '',
      analog_name: '',
      vat: '',
      count: '',
      price: '',
      category: '',
      group_id: '',
      note: '',
      image_ids: [],
      document_ids: [],
      tnv: '',
      ean: '',
      packing_qty: '',
      packing_net: '',
      packing_gross: '',
      packing_meas: '',
      delivery_type: '',
    },
    removeConfirmVisible: false,
  }),
  computed: {
    isAdmin() {
      return this.$store.getters['profile/isAdmin'];
    },
    productFieldsData() {
      return productFieldsData;
    },
    notFilledRequiredFields() {
      return ['group_id', 'name', 'index', 'delivery_type', 'count', 'price'].filter(requiredField => !this.productToCreate[requiredField]);
    },
    isRadioDisabled() {
      return this.onRequest && this.$store.getters['profile/isSupplierOnly'] && !!this.productToCreate.delivery_type;
    },
    isDisableRequestFields() {
      return this.onRequest && !this.isAdmin;
    },
  },
  watch: {
    isShowAnalogBlock(val) {
      if (!val) {
        this.productToCreate.analog_name = '';
        this.productToCreate.analog_index = '';
      }
    },
  },
  async mounted() {
    await this.$store.dispatch('products/fetch');
    if (this.isExistedProduct) {
      const productToEdit = JSON.stringify(this.productToEdit);
      this.productToCreate = { ...this.productToCreate, ...JSON.parse(productToEdit) };
      // TODO: remove hardcode 1 after groups implementation
      this.productToCreate.group_id = this.productToEdit.group?.id || 1;
      this.images = this.productToEdit.images;
      this.documents = this.productToEdit.documents;
      this.productToCreate.image_ids = this.images?.map(image => image.id) | [];
      this.productToCreate.document_ids = this.documents.map(document => document.id);
      if (this.productToCreate.delivery_type) {
        this.productToCreate.delivery_type = this.packingTypeOptions.find(type => type.value === this.productToCreate.delivery_type.toLowerCase())?.value;
      }

      this.isShowAnalogBlock = !!this.productToCreate.analog_name;
    }
  },
  methods: {
    setProductNumber(value) {
      this.productToCreate.index = value.toUpperCase();
    },
    setAnalogIndex(value) {
      this.productToCreate.analog_index = value.toUpperCase();
    },
    updatePacking(packing) {
      this.productToCreate = { ...this.productToCreate, ...packing };
    },
    remove() {
      this.$emit('remove-product', this.productToCreate.id);
    },
    removeIconClick() {
      if (this.productToCreate.isNeedSave) {
        this.removeConfirmVisible = true;
      } else {
        this.remove();
      }
    },
    updatePrice(price) {
      this.productToCreate.price = price;
    },
    updateQty(qty) {
      this.productToCreate.count = qty;
    },
    updateVat(vat) {
      this.productToCreate.vat = vat;
    },
    async disableProduct() {
      const { data } = await this.$http.post(`/products/decline/${this.productToCreate.id}`);
      this.$emit('update-product', data.data.product);
    },
    toggleAnalogBlock() {
      this.isShowAnalogBlock = !this.isShowAnalogBlock;
    },
    async saveProduct() {
      this.productToCreate.image_ids = this.images.map(item => item.id);
      this.productToCreate.document_ids = this.documents.map(item => item.id);

      if (this.isExistedProduct && ((!this.isProductFromItem && !this.productToCreate.isNeedSave) || this.createdProductsIndexes.includes(this.productToCreate.index))) {
        const { data } = await this.$http.put(`/products/update/${this.productToCreate.id}`, this.productToCreate);
        if (data.status) {
          this.setSystemNotification(this.$t('changesSaved'), 'CheckCircleIcon', 'success');
          this.$emit('update-product', data.data.product);
        }
        return;
      }
      const { data } = await this.$http.post('/products', this.productToCreate);
      if (data.status) {
        this.setSystemNotification(this.$t('changesSaved'), 'CheckCircleIcon', 'success');
        if (this.isProductFromItem || this.productToCreate.isNeedSave) {
          this.$emit('create-product', { data: data.data.product, itemIndexToRemove: this.productToCreate.index });
        } else {
          this.$emit('create-product', { data: data.data.product });
        }
      }
    },
    setSystemNotification(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      });
    },
    async uploadImages() {
      const images = Array.from(this.$refs.refInputEl.files);

      const imagesToSend = [];
      images.forEach(item => {
        // if (item.type.match('image')) {
        imagesToSend.push(item);
        // }
      });

      if (imagesToSend.length === 0) {
        return;
      }

      const newForm = new FormData();
      imagesToSend.forEach(image => newForm.append('images[]', image));
      const { data } = await this.$http.post('/images', newForm);
      this.images = [...this.images, ...data.data.images];
    },
    removeImage(index) {
      this.images.splice(index, 1);
      const transfer = new DataTransfer();
      const { files } = this.$refs.refInputEl;
      files.forEach((item, i) => {
        if (i !== index) {
          transfer.items.add(item);
        }
      });
      this.$refs.refInputEl.files = transfer.files;
    },
    async uploadDocuments() {
      const documentsToSend = Array.from(this.$refs.refInputDocumentEl.files);

      if (documentsToSend.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('documentNotSupported'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });

        return;
      }

      const newForm = new FormData();
      newForm.append('type', CERTIFICATE);
      documentsToSend.forEach(document => newForm.append('documents[]', document));
      const { data } = await this.$http.post('/documents', newForm);
      this.documents = [...this.documents, ...data.data.documents];
    },
    removeDocument(index) {
      this.documents.splice(index, 1);

      const transfer = new DataTransfer();
      const { files } = this.$refs.refInputDocumentEl;
      files.forEach((item, i) => {
        if (i !== index) {
          transfer.items.add(item);
        }
      });
      this.$refs.refInputDocumentEl.files = transfer.files;
    },
    getDocumentImageUrl(document) {
      if (document.url) {
        if (document.url.match('(png|jpg|jpeg)')) {
          return document.url;
        }

        if (document.url.match('pdf')) {
          return require('@/assets/images/placeholders/pdf-placeholder.svg');
        }
      }

      return require('@/assets/images/placeholders/product-placeholder.svg');
    },
    openDocument(document) {
      window.open(document.url, '_blank');
    },
    inputNumberFocus(mode, checkValue) {
      switch (mode) {
        case 'in':
          if (!this.productToCreate[checkValue] || !Number(this.productToCreate[checkValue])) this.productToCreate[checkValue] = '';
          break;
        case 'out':
          if (this.productToCreate[checkValue] === '') this.productToCreate[checkValue] = 0;
          break;
        default:
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../@core/scss/base/core/colors/palette-variables';
@import '../../../../@core/scss/base/components/variables-dark';
@import "../../../../@core/scss/base/pages/app-invoice";

.product-item {
  background-color: $product-details-bg;
  &-image {
    background-color: $product-details-inner-bg;
    border-radius: 5px;
    height: 80px;
  }
  .text-bold {
    font-weight: 600;
  }
}
#packing-type {
  ::v-deep .custom-radio label span {
    font-weight: 600;
  }
}

.product-new-image-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: .5rem;
  .product-new-image-close {
    color: #b10d0d;
    opacity: 0;
    transition: all .4s ease;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
  .product-new-image-show {
    color: #6559f2;
    opacity: 0;
    transition: all .4s ease;
    position: absolute;
    z-index: 1;
  }
  .product-new-image {
    position: relative;
    user-select: none;
  }
  &:hover {
    .product-new-image-close, .product-new-image-show {
      opacity: 1;
      z-index: 100;
    }

    .product-new-image {
      opacity: 0.6;
    }
  }
}
.divider {
  margin: 0;
}
</style>

<style lang="scss">
@import '../../../../@core/scss/base/core/colors/palette-variables';
@import '../../../../@core/scss/base/components/variables-dark';
.dark-layout {
  .product-item {
    background-color: $theme-dark-body-bg;
  }
}
</style>

<style scoped lang="scss">
.tooltip-button {
  width: fit-content;
}
</style>
