// this is data from custom parse function for chapter XVI

const tnVedTreeData = [
  {
    label: '8: XVI. Машины, оборудование и механизмы; электротехническое оборудование; их части; звукозаписывающая и звуковоспроизводящая аппаратура, аппаратура для записи и воспроизведения телевизионного изображения и звука, их части и принадлежности (Группы 84-85)',
    children: [
      {
        label: '84: Реакторы ядерные, котлы, оборудование и механические устройства; их части',
        children: [
          {
            label: '8401: Реакторы ядерные; тепловыделяющие элементы (твэлы), необлученные, для ядерных реакторов; оборудование и устройства для разделения изотопов',
            children: [
              {
                label: '8401100000: реакторы ядерные',
                children: [],
                id: '8401100000',
              },
              {
                label: '8401200000: оборудование и устройства для разделения изотопов, их части',
                children: [],
                id: '8401200000',
              },
              {
                label: '8401300000: тепловыделяющие элементы (твэлы), необлученные',
                children: [],
                id: '8401300000',
              },
              {
                label: '8401400000: части ядерных реакторов',
                children: [],
                id: '8401400000',
              },
            ],
            id: '8401',
          },
          {
            label: '8402: Котлы паровые или другие паропроизводящие котлы (кроме водяных котлов центрального отопления, способных также производить пар низкого давления); котлы перегретой воды',
            children: [
              {
                label: '84021: котлы паровые или другие паропроизводящие котлы',
                children: [
                  {
                    label: '840211: котлы водотрубные производительностью более 45 т пара в час',
                    children: [
                      {
                        label: '8402110001: котлы паровые для судового оборудования3*)\n\t\t\t\t\t\t\t\tПри подтверждении уполномоченным в области транспорта органом исполнительной власти государства – члена Евразийского экономического союза целевого назначения ввозимого товара.',
                        children: [],
                        id: '8402110001',
                      },
                      {
                        label: '8402110009: прочие',
                        children: [],
                        id: '8402110009',
                      },
                    ],
                    id: '840211',
                  },
                  {
                    label: '840212: котлы водотрубные производительностью не более 45 т пара в час',
                    children: [
                      {
                        label: '8402120001: котлы паровые для судового оборудования3*)\n\t\t\t\t\t\t\t\tПри подтверждении уполномоченным в области транспорта органом исполнительной власти государства – члена Евразийского экономического союза целевого назначения ввозимого товара.',
                        children: [],
                        id: '8402120001',
                      },
                      {
                        label: '8402120009: прочие',
                        children: [],
                        id: '8402120009',
                      },
                    ],
                    id: '840212',
                  },
                  {
                    label: '840219: паропроизводящие котлы прочие, включая комбинированные',
                    children: [
                      {
                        label: '8402191: котлы дымогарные',
                        children: [],
                        id: '8402191',
                      },
                      {
                        label: '8402199: прочие',
                        children: [],
                        id: '8402199',
                      },
                    ],
                    id: '840219',
                  },
                ],
                id: '84021',
              },
              {
                label: '84022: котлы перегретой воды',
                children: [
                  {
                    label: '8402200001: для судового оборудования3*)\n\t\t\t\t\t\t\t\tПри подтверждении уполномоченным в области транспорта органом исполнительной власти государства – члена Евразийского экономического союза целевого назначения ввозимого товара.',
                    children: [],
                    id: '8402200001',
                  },
                  {
                    label: '8402200009: прочие',
                    children: [],
                    id: '8402200009',
                  },
                ],
                id: '84022',
              },
              {
                label: '84029: части',
                children: [
                  {
                    label: '8402900001: котлов паровых и котлов перегретой воды для судового оборудования3*)\n\t\t\t\t\t\t\t\tПри подтверждении уполномоченным в области транспорта органом исполнительной власти государства – члена Евразийского экономического союза целевого назначения ввозимого товара.',
                    children: [],
                    id: '8402900001',
                  },
                  {
                    label: '8402900009: прочие',
                    children: [],
                    id: '8402900009',
                  },
                ],
                id: '84029',
              },
            ],
            id: '8402',
          },
          {
            label: '8403: Котлы центрального отопления, кроме котлов товарной позиции 8402',
            children: [
              {
                label: '84031: котлы',
                children: [
                  {
                    label: '8403101000: из чугунного литья',
                    children: [],
                    id: '8403101000',
                  },
                  {
                    label: '8403109000: прочие',
                    children: [],
                    id: '8403109000',
                  },
                ],
                id: '84031',
              },
              {
                label: '84039: части',
                children: [],
                id: '84039',
              },
            ],
            id: '8403',
          },
          {
            label: '8404: Вспомогательное оборудование для использования с котлами товарной позиции 8402 или 8403 (например, экономайзеры, пароперегреватели, сажеудалители, газовые рекуператоры); конденсаторы для пароводяных или других паровых силовых установок',
            children: [],
            id: '8404',
          },
          {
            label: '8405: Газогенераторы или генераторы водяного газа с очистительными установками или без них; газогенераторы ацетиленовые и аналогичные газогенераторы с очистительными установками или без них',
            children: [],
            id: '8405',
          },
          {
            label: '8406: Турбины на водяном пару и турбины паровые прочие',
            children: [],
            id: '8406',
          },
          {
            label: '8407: Двигатели внутреннего сгорания с искровым зажиганием, с вращающимся или возвратно-поступательным движением поршня',
            children: [],
            id: '8407',
          },
          {
            label: '8408: Двигатели внутреннего сгорания поршневые с воспламенением от сжатия (дизели или полудизели)',
            children: [],
            id: '8408',
          },
          {
            label: '8409: Части, предназначенные исключительно или главным образом для двигателей товарной позиции 8407 или 8408',
            children: [],
            id: '8409',
          },
          {
            label: '8410: Турбины гидравлические, колеса водяные и регуляторы к ним',
            children: [],
            id: '8410',
          },
          {
            label: '8411: Двигатели турбореактивные и турбовинтовые, газовые турбины прочие',
            children: [],
            id: '8411',
          },
          {
            label: '8412: Двигатели и силовые установки прочие',
            children: [],
            id: '8412',
          },
          {
            label: '8413: Насосы жидкостные с расходомерами или без них; подъемники жидкостей',
            children: [],
            id: '8413',
          },
          {
            label: '8414: [с 01.01.22] Насосы воздушные или вакуумные, воздушные или газовые компрессоры и вентиляторы; вентиляционные или рециркуляционные вытяжные колпаки или шкафы с вентилятором, с фильтрами или без фильтров; газонепроницаемые шкафы биологической безопасности с фильтрами или без фильтров/[по 31.12.21] Насосы воздушные или вакуумные, воздушные или газовые компрессоры и вентиляторы; вентиляционные или рециркуляционные вытяжные колпаки или шкафы с вентилятором, с фильтрами или без фильтров',
            children: [],
            id: '8414',
          },
          {
            label: '8415: Установки для кондиционирования воздуха, оборудованные вентилятором с двигателем и приборами для изменения температуры и влажности воздуха, включая кондиционеры, в которых влажность не может регулироваться отдельно',
            children: [],
            id: '8415',
          },
          {
            label: '8416: Горелки топочные для жидкого топлива, распыленного твердого топлива или для газа; топки механические, включая их механические колосниковые решетки, механические золоудалители и аналогичные устройства',
            children: [],
            id: '8416',
          },
          {
            label: '8417: Горны и печи промышленные или лабораторные, включая мусоросжигательные печи, неэлектрические',
            children: [],
            id: '8417',
          },
          {
            label: '8418: Холодильники, морозильники и прочее холодильное или морозильное оборудование электрическое или других типов; тепловые насосы, кроме установок для кондиционирования воздуха товарной позиции 8415',
            children: [],
            id: '8418',
          },
          {
            label: '8419: Машины, оборудование промышленное или лабораторное с электрическим или неэлектрическим нагревом (исключая печи, камеры и другое оборудование товарной позиции 8514) для обработки материалов в процессе с изменением температуры, таком как нагрев, варка, жаренье, дистилляция, ректификация, стерилизация, пастеризация, пропаривание, сушка, выпаривание, конденсирование или охлаждение, за исключением машин и оборудования, используемых в бытовых целях; водонагреватели проточные или накопительные (емкостные), неэлектрические',
            children: [],
            id: '8419',
          },
          {
            label: '8420: Каландры или другие валковые машины, кроме машин для обработки металла или стекла, и валки для них',
            children: [],
            id: '8420',
          },
          {
            label: '8421: Центрифуги, включая центробежные сушилки; оборудование и устройства для фильтрования или очистки жидкостей или газов',
            children: [],
            id: '8421',
          },
          {
            label: '8422: Машины посудомоечные; оборудование для мойки или сушки бутылок или других емкостей; оборудование для заполнения, закупорки бутылок, банок, закрывания ящиков, мешков или других емкостей, для опечатывания их или этикетирования; оборудование для герметичной укупорки колпаками или крышками бутылок, банок, туб и аналогичных емкостей; оборудование для упаковки или обертки (включая оборудование, обертывающее товар с термоусадкой упаковочного материала) прочее; оборудование для газирования напитков',
            children: [],
            id: '8422',
          },
          {
            label: '8423: Оборудование для взвешивания (кроме весов чувствительностью 0,05 г или выше), включая счетные или контрольные машины, приводимые в действие силой тяжести взвешиваемого груза; разновесы для весов всех типов',
            children: [],
            id: '8423',
          },
          {
            label: '8424: Механические устройства (с ручным управлением или без него) для метания, разбрызгивания или распыления жидкостей или порошков; огнетушители заряженные или незаряженные; пульверизаторы и аналогичные устройства; пароструйные или пескоструйные и аналогичные метательные устройства',
            children: [],
            id: '8424',
          },
          {
            label: '8425: Тали подъемные и подъемники, кроме скиповых подъемников; лебедки и кабестаны; домкраты',
            children: [],
            id: '8425',
          },
          {
            label: '8426: Судовые деррик-краны; краны подъемные, включая кабель-краны; фермы подъемные подвижные, погрузчики портальные и тележки, оснащенные подъемным краном',
            children: [],
            id: '8426',
          },
          {
            label: '8427: [с 20.09.17] Автопогрузчики с вилочным захватом; прочие тележки, оснащенные подъемным или погрузочно-разгрузочным оборудованием/[по 19.09.17] Автопогрузчики с вилочным захватом; прочие погрузчики, оснащенные подъемным или погрузочно-разгрузочным оборудованием',
            children: [],
            id: '8427',
          },
          {
            label: '8428: Машины и устройства для подъема, перемещения, погрузки или разгрузки (например, лифты, эскалаторы, конвейеры, канатные дороги) прочие',
            children: [],
            id: '8428',
          },
          {
            label: '8429: Бульдозеры с неповоротным или поворотным отвалом, грейдеры, планировщики, скреперы, механические лопаты, экскаваторы, одноковшовые погрузчики, трамбовочные машины и дорожные катки, самоходные',
            children: [],
            id: '8429',
          },
          {
            label: '8430: Машины и механизмы прочие для перемещения, планировки, профилирования, разработки, трамбования, уплотнения, выемки или бурения грунта, полезных ископаемых или руд; оборудование для забивки и извлечения свай; снегоочистители плужные и роторные',
            children: [],
            id: '8430',
          },
          {
            label: '8431: Части, предназначенные исключительно или в основном для оборудования товарных позиций 8425 - 8430',
            children: [],
            id: '8431',
          },
          {
            label: '8432: Машины сельскохозяйственные, садовые или лесохозяйственные для подготовки и обработки почвы; катки для газонов или спортплощадок',
            children: [],
            id: '8432',
          },
          {
            label: '8433: Машины или механизмы для уборки или обмолота сельскохозяйственных культур, включая пресс-подборщики, прессы для упаковки в кипы соломы или сена; сенокосилки или газонокосилки; машины для очистки, сортировки или калибровки яиц, плодов или других сельскохозяйственных продуктов, кроме машин товарной позиции 8437',
            children: [],
            id: '8433',
          },
          {
            label: '8434: Установки и аппараты доильные, оборудование для обработки и переработки молока',
            children: [],
            id: '8434',
          },
          {
            label: '8435: Прессы, дробилки и аналогичное оборудование для виноделия, производства сидра, фруктовых соков или аналогичных напитков',
            children: [],
            id: '8435',
          },
          {
            label: '8436: Оборудование для сельского хозяйства, садоводства, лесного хозяйства, птицеводства или пчеловодства, включая оборудование для проращивания семян с механическими или нагревательными устройствами, прочее; инкубаторы для птицеводства и брудеры',
            children: [],
            id: '8436',
          },
          {
            label: '8437: Машины для очистки, сортировки или калибровки семян, зерна или сухих бобовых овощей; оборудование для мукомольной промышленности или для обработки злаковых или сухих бобовых овощей, кроме оборудования, используемого на сельскохозяйственных фермах',
            children: [],
            id: '8437',
          },
          {
            label: '8438: [с 01.01.22] Оборудование для промышленного приготовления или производства пищевых продуктов или напитков, в другом месте данной группы не поименованное или не включенное, кроме оборудования для экстрагирования или приготовления жиров или масел животного происхождения или нелетучих жиров или масел растительного или микробиологического происхождения/[по 31.12.21] Оборудование для промышленного приготовления или производства пищевых продуктов или напитков, в другом месте данной группы не поименованное или не включенное, кроме оборудования для экстрагирования или приготовления животных или нелетучих растительных жиров или масел',
            children: [],
            id: '8438',
          },
          {
            label: '8439: Оборудование для производства массы из волокнистых целлюлозных материалов или для изготовления или отделки бумаги или картона',
            children: [],
            id: '8439',
          },
          {
            label: '8440: Оборудование переплетное, включая машины для сшивания книжных блоков',
            children: [],
            id: '8440',
          },
          {
            label: '8441: Оборудование для производства изделий из бумажной массы, бумаги или картона, включая резательные машины всех типов, прочее',
            children: [],
            id: '8441',
          },
          {
            label: '8442: Машины, аппаратура и оснастка (кроме оборудования товарных позиций 8456 - 8465) для подготовки или изготовления пластин, цилиндров или других печатных форм; пластины, цилиндры и другие печатные формы; пластины, цилиндры и литографские камни, подготовленные для печатных целей (например, обточенные, шлифованные или полированные)',
            children: [],
            id: '8442',
          },
          {
            label: '8443: Машины печатные, используемые для печати посредством пластин, цилиндров и других печатных форм товарной позиции 8442; прочие принтеры, копировальные аппараты и факсимильные аппараты, объединенные или необъединенные; их части и принадлежности',
            children: [],
            id: '8443',
          },
          {
            label: '8444: Машины для экструдирования, вытягивания, текстурирования или резания химических текстильных материалов',
            children: [],
            id: '8444',
          },
          {
            label: '8445: Машины для подготовки текстильных волокон; прядильные, тростильные или крутильные машины и другое оборудование для изготовления текстильной пряжи; кокономотальные или мотальные (включая уточномотальные) текстильные машины и машины, подготавливающие текстильную пряжу для использования ее на машинах товарной позиции 8446 или 8447',
            children: [],
            id: '8445',
          },
          {
            label: '8446: Станки ткацкие',
            children: [],
            id: '8446',
          },
          {
            label: '8447: Машины трикотажные, вязальнопрошивные, для получения позументной нити, тюля, кружев, вышивания, плетения тесьмы или сетей и тафтинговые машины',
            children: [],
            id: '8447',
          },
          {
            label: '8448: Оборудование вспомогательное для использования с машинами товарной позиции 8444, 8445, 8446 или 8447 (например, ремизоподъемные каретки, жаккардовые машины, автоматические механизмы останова, механизмы смены челноков); части и принадлежности, предназначенные исключительно или в основном для машин данной товарной позиции или товарной позиции 8444, 8445, 8446 или 8447 (например, веретена и рогульки, игольчатая гарнитура, гребни, фильеры, челноки, ремизки и ремизные рамы, трикотажные иглы)',
            children: [],
            id: '8448',
          },
          {
            label: '8449000000: Оборудование для производства или отделки войлока или фетра или нетканых материалов в куске или в крое, включая оборудование для производства фетровых шляп; болваны для изготовления шляп',
            children: [],
            id: '8449000000',
          },
          {
            label: '8450: Машины стиральные, бытовые или для прачечных, включая машины, оснащенные отжимным устройством',
            children: [],
            id: '8450',
          },
          {
            label: '8451: ',
            children: [],
            id: '8451',
          },
          {
            label: '8452: Машины швейные, кроме машин для сшивания книжных блоков товарной позиции 8440; мебель, основания и футляры, предназначенные специально для швейных машин; иглы для швейных машин',
            children: [],
            id: '8452',
          },
          {
            label: '8453: Оборудование для подготовки, дубления или обработки шкур или кож или для изготовления или ремонта обуви или прочих изделий из шкур или кож, кроме швейных машин',
            children: [],
            id: '8453',
          },
          {
            label: '8454: Конвертеры, литейные ковши, изложницы и машины литейные, используемые в металлургии или литейном производстве',
            children: [],
            id: '8454',
          },
          {
            label: '8455: Станы металлопрокатные и валки для них',
            children: [],
            id: '8455',
          },
          {
            label: '8456: Станки для обработки любых материалов путем удаления материала с помощью лазерного или другого светового или фотонного луча, ультразвуковых, электроразрядных, электрохимических, электронно-лучевых, ионно-лучевых или плазменно-дуговых процессов; водоструйные резательные машины',
            children: [],
            id: '8456',
          },
          {
            label: '8457: Центры обрабатывающие, станки агрегатные однопозиционные и многопозиционные, для обработки металла',
            children: [],
            id: '8457',
          },
          {
            label: '8458: Станки токарные (включая станки токарные многоцелевые) металлорежущие',
            children: [],
            id: '8458',
          },
          {
            label: '8459: Станки металлорежущие (включая агрегатные станки линейного построения) для сверления, растачивания, фрезерования, нарезания наружной или внутренней резьбы посредством удаления металла, кроме токарных станков (включая станки токарные многоцелевые) товарной позиции 8458',
            children: [],
            id: '8459',
          },
          {
            label: '8460: Станки обдирочно-шлифовальные, заточные, шлифовальные, хонинговальные, притирочные, полировальные и для выполнения других операций чистовой обработки металлов или металлокерамики с помощью шлифовальных камней, абразивов или полирующих средств, кроме зуборезных, зубошлифовальных или зубоотделочных станков товарной позиции 8461',
            children: [],
            id: '8460',
          },
          {
            label: '8461: Станки продольно-строгальные, поперечно-строгальные, долбежные, протяжные, зуборезные, зубошлифовальные или зубоотделочные, пильные, отрезные и другие станки для обработки металлов или металлокерамики посредством удаления материала, в других местах не поименованные или не включенные',
            children: [],
            id: '8461',
          },
          {
            label: '8462: [с 01.01.22] Станки (включая прессы) для обработки металлов объемной штамповкой, ковкой или штамповкой (кроме прокатных станов); станки для обработки металлов (включая прессы, линии продольной резки и линии поперечной резки) гибочные, кромкогибочные, правильные, отрезные, пробивные, вырубные или высечные (кроме станков для волочения); прессы для обработки металлов или карбидов металлов, не поименованные выше/[по 31.12.21] Станки (включая прессы) для обработки металлов объемной штамповкой, ковкой или штамповкой; станки для обработки металлов (включая прессы) гибочные, кромкогибочные, правильные, отрезные, пробивные или вырубные; прессы для обработки металлов или карбидов металлов, не поименованные выше',
            children: [],
            id: '8462',
          },
          {
            label: '8463: Станки для обработки металлов или металлокерамики без удаления материала прочие',
            children: [],
            id: '8463',
          },
          {
            label: '8464: Станки для обработки камня, керамики, бетона, асбоцемента или аналогичных минеральных материалов или для холодной обработки стекла',
            children: [],
            id: '8464',
          },
          {
            label: '8465: Станки (включая машины для сборки с помощью гвоздей, скоб, клея или другими способами) для обработки дерева, пробки, кости, твердой резины, твердых пластмасс или аналогичных твердых материалов',
            children: [],
            id: '8465',
          },
          {
            label: '8466: Части и принадлежности, предназначенные исключительно или в основном для оборудования товарных позиций 8456 - 8465, включая приспособления для крепления инструмента или деталей, самораскрывающиеся резьбонарезные головки, делительные головки и другие специальные приспособления к оборудованию; приспособления для крепления рабочих инструментов для всех типов ручных инструментов',
            children: [],
            id: '8466',
          },
          {
            label: '8467: Инструменты ручные пневматические, гидравлические или со встроенным электрическим или неэлектрическим двигателем',
            children: [],
            id: '8467',
          },
          {
            label: '8468: Оборудование и аппараты для низкотемпературной пайки, высокотемпературной пайки или сварки, пригодные или не пригодные для резки, кроме машин и аппаратов товарной позиции 8515; машины и аппараты для поверхностной термообработки, работающие на газе',
            children: [],
            id: '8468',
          },
          {
            label: '8470: Машины счетные и карманные машины для записи, воспроизведения и визуального представления данных с вычислительными функциями; бухгалтерские машины, почтовые маркировочные машины, аппараты билетные и другие аналогичные машины со счетными устройствами; аппараты кассовые',
            children: [],
            id: '8470',
          },
          {
            label: '8471: Вычислительные машины и их блоки; магнитные или оптические считывающие устройства, машины для переноса данных на носители информации в кодированной форме и машины для обработки подобной информации, в другом месте не поименованные или не включенные',
            children: [],
            id: '8471',
          },
          {
            label: '8472: Оборудование конторское (например, гектографические или трафаретные множительные аппараты, машины адресовальные, автоматические устройства для выдачи банкнот, машины для сортировки, подсчета или упаковки монет, машинки для заточки карандашей, перфорационные машины или машины для скрепления скобами) прочее',
            children: [],
            id: '8472',
          },
          {
            label: '8473: Части и принадлежности (кроме футляров, чехлов для транспортировки и аналогичных изделий), предназначенные исключительно или в основном для машин товарных позиций 8470 - 8472',
            children: [],
            id: '8473',
          },
          {
            label: '8474: Оборудование для сортировки, грохочения, сепарации, промывки, измельчения, размалывания, смешивания или перемешивания грунта, камня, руд или других минеральных ископаемых в твердом (в том числе порошкообразном или пастообразном) состоянии; оборудование для агломерации, формовки или отливки твердого минерального топлива, керамических составов, незатвердевшего цемента, гипсовых материалов или других минеральных продуктов в порошкообразном или пастообразном состоянии; машины формовочные для изготовления литейных форм из песка',
            children: [],
            id: '8474',
          },
          {
            label: '8475: Машины для сборки электрических или электронных ламп, трубок или электронно-лучевых трубок или газоразрядных ламп в стеклянных колбах; машины для изготовления или горячей обработки стекла или изделий из стекла',
            children: [],
            id: '8475',
          },
          {
            label: '8476: Автоматы торговые (например, для продажи почтовых марок, сигарет, продовольственных товаров или напитков), включая автоматы для размена банкнот и монет',
            children: [],
            id: '8476',
          },
          {
            label: '8477: Оборудование для обработки резины или пластмасс или для производства продукции из этих материалов, в другом месте данной группы не поименованное или не включенное',
            children: [],
            id: '8477',
          },
          {
            label: '8478: Оборудование для подготовки или приготовления табака, в другом месте данной группы не поименованное или не включенное',
            children: [],
            id: '8478',
          },
          {
            label: '8479: Машины и механические устройства, имеющие индивидуальные функции, в другом месте данной группы не поименованные или не включенные',
            children: [],
            id: '8479',
          },
          {
            label: '8480: Опоки для металлолитейного производства; литейные поддоны; модели литейные; формы для литья металлов (кроме изложниц), карбидов металлов, стекла, минеральных материалов, резины или пластмасс',
            children: [],
            id: '8480',
          },
          {
            label: '8481: Краны, клапаны, вентили и аналогичная арматура для трубопроводов, котлов, резервуаров, цистерн, баков или аналогичных емкостей, включая редукционные и терморегулируемые клапаны',
            children: [],
            id: '8481',
          },
          {
            label: '8482: Подшипники шариковые или роликовые',
            children: [],
            id: '8482',
          },
          {
            label: '8483: Валы трансмиссионные (включая кулачковые и коленчатые) и кривошипы; корпуса подшипников и подшипники скольжения для валов; шестерни и зубчатые передачи; шариковые или роликовые винтовые передачи; коробки передач и другие вариаторы скорости, включая гидротрансформаторы; маховики и шкивы, включая блоки шкивов; муфты и устройства для соединения валов (включая универсальные шарниры)',
            children: [],
            id: '8483',
          },
          {
            label: '8484: Прокладки и аналогичные соединительные элементы из листового металла в сочетании с другим материалом или состоящие из двух или более слоев металла; наборы или комплекты прокладок и аналогичных соединительных элементов, различных по составу, упакованные в пакеты, конверты или аналогичную упаковку; механические уплотнения',
            children: [],
            id: '8484',
          },
          {
            label: '8486: Машины и аппаратура, используемые исключительно или в основном для производства полупроводниковых булей или пластин, полупроводниковых приборов, электронных интегральных схем или плоских дисплейных панелей; машины и аппаратура, поименованные в примечании 11 (В) к данной группе; части и принадлежности',
            children: [],
            id: '8486',
          },
          {
            label: '8487: Части оборудования, не имеющие электрических соединений, изоляторов, контактов, катушек или других электрических деталей, в другом месте данной группы не поименованные или не включенные',
            children: [],
            id: '8487',
          },
        ],
        id: '84',
      },
      {
        label: '85: Электрические машины и оборудование, их части; звукозаписывающая и звуковоспроизводящая аппаратура, аппаратура для записи и воспроизведения телевизионного изображения и звука, их части и принадлежности',
        children: [
          {
            label: '8501: Двигатели и генераторы электрические (кроме электрогенераторных установок)',
            children: [],
            id: '8501',
          },
          {
            label: '8502: Электрогенераторные установки и вращающиеся электрические преобразователи',
            children: [],
            id: '8502',
          },
          {
            label: '8503: Части, предназначенные исключительно или в основном для машин товарной позиции 8501 или 8502',
            children: [],
            id: '8503',
          },
          {
            label: '8504: Трансформаторы электрические, статические электрические преобразователи (например, выпрямители), катушки индуктивности и дроссели',
            children: [],
            id: '8504',
          },
          {
            label: '8505: Электромагниты; постоянные магниты и изделия, предназначенные для превращения в постоянные магниты после намагничивания; электромагнитные или с постоянными магнитами зажимные патроны, захваты и аналогичные фиксирующие устройства; электромагнитные сцепления, муфты и тормоза; электромагнитные подъемные головки',
            children: [],
            id: '8505',
          },
          {
            label: '8506: Первичные элементы и первичные батареи',
            children: [],
            id: '8506',
          },
          {
            label: '8507: Аккумуляторы электрические, включая сепараторы для них, прямоугольной (в том числе квадратной) или иной формы',
            children: [],
            id: '8507',
          },
          {
            label: '8508: Пылесосы',
            children: [],
            id: '8508',
          },
          {
            label: '8509: Машины электромеханические бытовые со встроенным электродвигателем, кроме пылесосов товарной позиции 8508',
            children: [],
            id: '8509',
          },
          {
            label: '8510: Электробритвы, машинки для стрижки волос и приспособления для удаления волос со встроенным электродвигателем',
            children: [],
            id: '8510',
          },
          {
            label: '8511: Электрооборудование для зажигания или пуска двигателей внутреннего сгорания с искровым зажиганием или с воспламенением от сжатия (например, магнето, катушки зажигания, свечи зажигания, свечи накаливания, стартеры); генераторы (например, постоянного или переменного тока) и прерыватели типа используемых вместе с такими двигателями:',
            children: [],
            id: '8511',
          },
          {
            label: '8512: Оборудование электроосветительное или сигнализационное (кроме изделий товарной позиции 8539), стеклоочистители, антиобледенители и противозапотеватели, используемые на велосипедах или моторных транспортных средствах',
            children: [],
            id: '8512',
          },
          {
            label: '8513: Фонари портативные электрические, работающие от собственного источника энергии (например, батарей сухих элементов, аккумуляторов, магнето), кроме осветительного оборудования товарной позиции 8512',
            children: [],
            id: '8513',
          },
          {
            label: '8514: Печи и камеры промышленные или лабораторные электрические (включая действующие на основе явления индукции или диэлектрических потерь); промышленное или лабораторное оборудование для термической обработки материалов с помощью явления индукции или диэлектрических потерь',
            children: [],
            id: '8514',
          },
          {
            label: '8515: Машины и аппараты для электрической (в том числе с электрическим нагревом газа), лазерной или другой световой или фотонной, ультразвуковой, электронно-лучевой, магнитно-импульсной или плазменно-дуговой низкотемпературной пайки, высокотемпературной пайки или сварки независимо от того, могут ли они выполнять операции резания или нет; машины и аппараты электрические для горячего напыления металлов или металлокерамики',
            children: [],
            id: '8515',
          },
          {
            label: '8516: [с 20.09.17] Электрические водонагреватели проточные или накопительные (емкостные) и электронагреватели погружные; электрооборудование обогрева пространства и обогрева грунта, электротермические аппараты для ухода за волосами (например, сушилки для волос, бигуди, щипцы для горячей завивки) и сушилки для рук; электроутюги; прочие бытовые электронагревательные приборы; электрические нагревательные сопротивления, кроме указанных в товарной позиции 8545/[по 19.09.17] Электрические водонагреватели проточные или накопительные (емкостные), электронагреватели погружные; электрооборудование обогрева пространства и обогрева грунта, электротермические аппараты для ухода за волосами (например, сушилки для волос, бигуди, щипцы для горячей завивки) и сушилки для рук; электроутюги; прочие бытовые электронагревательные приборы; электрические нагревательные сопротивления, кроме указанных в товарной позиции 8545',
            children: [],
            id: '8516',
          },
          {
            label: '8517: Аппараты телефонные, включая аппараты телефонные для сотовых сетей связи или других беспроводных сетей связи; прочая аппаратура для передачи или приема голоса, изображений или других данных, включая аппаратуру для коммуникации в сети проводной или беспроводной связи (например, в локальной или глобальной сети связи), кроме передающей или приемной аппаратуры товарной позиции 8443, 8525, 8527 или 8528',
            children: [],
            id: '8517',
          },
          {
            label: '8518: Микрофоны и подставки для них; громкоговорители, смонтированные или не смонтированные в корпусах; наушники и телефоны головные, объединенные или не объединенные с микрофоном, и комплекты, состоящие из микрофона и одного или более громкоговорителей; электрические усилители звуковой частоты; электрические звукоусилительные комплекты',
            children: [],
            id: '8518',
          },
          {
            label: '8519: Аппаратура звукозаписывающая или звуковоспроизводящая',
            children: [],
            id: '8519',
          },
          {
            label: '8521: Аппаратура видеозаписывающая или видеовоспроизводящая, совмещенная или не совмещенная с видеотюнером',
            children: [],
            id: '8521',
          },
          {
            label: '8522: Части и принадлежности, пригодные к использованию исключительно или в основном с аппаратурой товарной позиции 8519 или 8521',
            children: [],
            id: '8522',
          },
          {
            label: '8523: Диски, ленты, твердотельные энергонезависимые устройства хранения данных, "интеллектуальные карточки" и другие носители для записи звука или других явлений, записанные или незаписанные, включая матрицы и мастер-диски для изготовления дисков, кроме изделий группы 37',
            children: [],
            id: '8523',
          },
          {
            label: '8524: Модули с плоской дисплейной панелью, в том числе с сенсорным экраном',
            children: [],
            id: '8524',
          },
          {
            label: '8525: Аппаратура передающая для радиовещания или телевидения, включающая или не включающая в свой состав приемную, звукозаписывающую или звуковоспроизводящую аппаратуру; телевизионные камеры, цифровые камеры и записывающие видеокамеры',
            children: [],
            id: '8525',
          },
          {
            label: '8526: Аппаратура радиолокационная, радионавигационная и радиоаппаратура дистанционного управления',
            children: [],
            id: '8526',
          },
          {
            label: '8527: Аппаратура приемная для радиовещания, совмещенная или не совмещенная в одном корпусе со звукозаписывающей или звуковоспроизводящей аппаратурой или часами',
            children: [],
            id: '8527',
          },
          {
            label: '8528: Мониторы и проекторы, не включающие в свой состав приемную телевизионную аппаратуру; аппаратура приемная для телевизионной связи, включающая или не включающая в свой состав широковещательный радиоприемник или аппаратуру, записывающую или воспроизводящую звук или изображение',
            children: [],
            id: '8528',
          },
          {
            label: '8529: Части, предназначенные исключительно или в основном для аппаратуры товарных позиций 8524 - 8528',
            children: [],
            id: '8529',
          },
          {
            label: '8530: Электрические устройства сигнализации, обеспечения безопасности или управления движением для железных дорог, трамвайных путей, автомобильных дорог, внутренних водных путей, парковочных сооружений, портов или аэродромов (кроме оборудования товарной позиции 8608)',
            children: [],
            id: '8530',
          },
          {
            label: '8531: Электрооборудование звуковое или визуальное сигнализационное (например, звонки, сирены, индикаторные панели, устройства сигнализационные охранные или устройства для подачи пожарного сигнала), кроме оборудования товарной позиции 8512 или 8530',
            children: [],
            id: '8531',
          },
          {
            label: '8532: Конденсаторы электрические постоянные, переменные или подстроечные',
            children: [],
            id: '8532',
          },
          {
            label: '8533: Резисторы электрические (включая реостаты и потенциометры), кроме нагревательных элементов',
            children: [],
            id: '8533',
          },
          {
            label: '8534: Схемы печатные',
            children: [],
            id: '8534',
          },
          {
            label: '8535: Аппаратура электрическая для коммутации или защиты электрических цепей или для подсоединений к электрическим цепям или в электрических цепях (например, выключатели, переключатели, прерыватели, плавкие предохранители, молниеотводы, ограничители напряжения, гасители скачков напряжения, токоприемники, токосъемники и прочие соединители, соединительные коробки) на напряжение более 1000 В',
            children: [],
            id: '8535',
          },
          {
            label: '8536: Аппаратура электрическая для коммутации или защиты электрических цепей или для подсоединений к электрическим цепям или в электрических цепях (например, выключатели, переключатели, прерыватели, реле, плавкие предохранители, гасители скачков напряжения, штепсельные вилки и розетки, патроны для электроламп и прочие соединители, соединительные коробки) на напряжение не более 1000 В; соединители для волокон оптических, волоконно-оптических жгутов или кабелей',
            children: [],
            id: '8536',
          },
          {
            label: '8537: Пульты, панели, консоли, столы, распределительные щиты и основания для электрической аппаратуры прочие, оборудованные двумя или более устройствами товарной позиции 8535 или 8536, для управления или распределения электрического тока, в том числе включающие в себя приборы или устройства группы 90 и цифровые аппараты управления, кроме коммутационных устройств товарной позиции 8517',
            children: [],
            id: '8537',
          },
          {
            label: '8538: Части, предназначенные исключительно или в основном для аппаратуры товарной позиции 8535, 8536 или 8537',
            children: [],
            id: '8538',
          },
          {
            label: '8539: [с 01.01.22] Лампы накаливания или газоразрядные, электрические, включая лампы герметичные направленного света, а также ультрафиолетовые или инфракрасные лампы; дуговые лампы; источники света светодиодные (LED)/[по 31.12.21] Лампы накаливания электрические или газоразрядные, включая лампы герметичные направленного света, а также ультрафиолетовые или инфракрасные лампы; дуговые лампы; лампы светоизлучающие диодные (LED)',
            children: [],
            id: '8539',
          },
          {
            label: '8540: Лампы и трубки электронные с термокатодом, холодным катодом или фотокатодом (например, вакуумные или паро- или газонаполненные лампы и трубки, ртутные дуговые выпрямительные лампы и трубки и электронно-лучевые трубки, телевизионные трубки передающие)',
            children: [],
            id: '8540',
          },
          {
            label: '8541: [с 01.01.22] Приборы полупроводниковые (например, диоды, транзисторы, преобразователи на основе полупроводников); фоточувствительные полупроводниковые приборы, включая фотогальванические элементы, собранные или не собранные в модули, вмонтированные или не вмонтированные в панели; светодиоды (LED), собранные или не собранные с другими светодиодами (LED); пьезоэлектрические кристаллы в сборе/[по 31.12.21] Диоды, транзисторы и аналогичные полупроводниковые приборы; фоточувствительные полупроводниковые приборы, включая фотогальванические элементы, собранные или не собранные в модули, вмонтированные или не вмонтированные в панели; светоизлучающие диоды (LED); пьезоэлектрические кристаллы в сборе',
            children: [],
            id: '8541',
          },
          {
            label: '8542: Схемы электронные интегральные',
            children: [],
            id: '8542',
          },
          {
            label: '8543: Машины электрические и аппаратура, имеющие индивидуальные функции, в другом месте данной группы не поименованные или не включенные',
            children: [],
            id: '8543',
          },
          {
            label: '8544: Провода изолированные (включая эмалированные или анодированные), кабели (включая коаксиальные кабели) и другие изолированные электрические проводники с соединительными приспособлениями или без них; кабели волоконно-оптические, составленные из волокон с индивидуальными оболочками, независимо от того, находятся они или нет в сборе с электропроводниками или соединительными приспособлениями',
            children: [],
            id: '8544',
          },
          {
            label: '8545: Электроды угольные, угольные щетки, угли для ламп или батареек и изделия из графита или других видов углерода с металлом или без металла, прочие, применяемые в электротехнике',
            children: [],
            id: '8545',
          },
          {
            label: '8546: Изоляторы электрические из любых материалов',
            children: [],
            id: '8546',
          },
          {
            label: '8547: Арматура изолирующая для электрических машин, устройств или оборудования, изготовленная полностью из изоляционных материалов, не считая некоторых металлических компонентов (например, резьбовых патронов), вмонтированных при формовке исключительно с целью сборки, кроме изоляторов товарной позиции 8546; трубки для электропроводки и соединительные детали для них, из недрагоценных металлов, облицованные изоляционным материалом',
            children: [],
            id: '8547',
          },
          {
            label: '8548: [с 01.01.22] Части электрические оборудования или аппаратуры, в другом месте данной группы не поименованные или не включенные/[по 31.12.21] Отходы и лом первичных элементов, первичных батарей и электрических аккумуляторов; отработавшие первичные элементы, отработавшие первичные батареи и отработавшие электрические аккумуляторы; электрические части оборудования или аппаратуры, в другом месте данной группы не поименованные или не включенные',
            children: [],
            id: '8548',
          },
          {
            label: '8549: Отходы и лом электротехнических и электронных изделий',
            children: [],
            id: '8549',
          },
        ],
        id: '85',
      },
    ],
    id: '8',
  },
];

// function removeDuplicateObjects(tree) {
//   const ids = new Set();
//
//   function traverse(node) {
//     if (!ids.has(node.id)) {
//       ids.add(node.id);
//     } else {
//       return null;
//     }
//
//     if (node.children && node.children.length > 0) {
//       node.children = node.children.map(traverse).filter(child => child !== null);
//     }
//
//     return node;
//   }
//
//   return traverse(tree);
// }
// const result = removeDuplicateObjects(tnVedTreeData[0]);
// console.log(result);

export default tnVedTreeData;
