import i18n from '@/libs/i18n';

export const offerStatuses = {
  0: {
    name: i18n.t('notPublished'),
    variant: 'deal__not-published',
  },
  1: {
    name: i18n.t('pendingVerification'),
    variant: 'deal__equipment',
  },
  2: {
    name: i18n.t('accepted'),
    variant: 'deal__accepted',
  },
  3: {
    name: i18n.t('rejected'),
    variant: 'deal__problem',
  },
  4: {
    name: i18n.t('published'),
    variant: 'deal__published',
  },
};
